import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import ApplePay from '../ApplePay';

type ApplePayDataProps = SliceProps<'applePay', SliceVariation<'default', Queries.PrismicApplePayDefaultPrimary>> &
  WithClassName;
/**
 * ApplePay data component: specifies the fragment and passes the appropriate data to the ApplePay component.
 */
const ApplePayData: FC<ApplePayDataProps> = ({ className, slice }) => (
  <ApplePay
    className={className}
    leftContent={{
      heading: slice.primary?.leftHeading,
      text: slice.primary?.leftContent,
    }}
    rightContent={{
      heading: slice.primary?.rightHeading,
      text: slice.primary?.rightContent,
    }}
    steps={{
      step1: slice.primary.step1 || '',
      step2: slice.primary.step2 || '',
      step3: slice.primary.step3 || '',
    }}
  />
);

export default ApplePayData;

export const query = graphql`
  fragment ApplePay on PrismicApplePayDefault {
    id
    primary {
      leftContent {
        ...RichText
      }
      leftHeading {
        ...RichText
      }
      rightContent {
        ...RichText
      }
      rightHeading {
        ...RichText
      }
      step3
      step2
      step1
    }
  }
`;
