import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Title from '../Title';

type TitleDataProps = SliceProps<'title', SliceVariation<'default', Queries.PrismicTitleDefaultPrimary>> &
  WithClassName;
/**
 * Title data component: specifies the fragment and passes the appropriate data to the Title component.
 */
const TitleData: FC<TitleDataProps> = ({ className, slice }) => (
  <Title className={className} heading={slice.primary?.heading} text={slice.primary.text || ''} />
);

export default TitleData;

export const query = graphql`
  fragment Title on PrismicTitleDefault {
    primary {
      heading {
        ...RichText
      }
      text
    }
  }
`;
