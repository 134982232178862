export const solidBackgrounds = {
  brand2: '#407A7D',
  brand2Dark: '#296366',
  brand2Mid: '#9AC6CD',
  brand2Mid2: '#CFEDF2',
  brand2Light: '#E3F7FA',
  brand3: '#06444E',
  brand3Dark: '#033740',
  brand3Mid: '#569B9E',
  brand3Mid2: '#C9EBED',
  brand3Light: '#DCF5F5',
  brand4: '#131F2E',
  brand4Dark: '#04080D',
  brand4Mid: '#334A66',
  brand4Mid2: '#DAE5F2',
  brand4Light: '#F0F3F7',
  brand5: '#F1F5F0',
  brand5Dark: '#BCC2BA',
  brand5Mid: '#D6DBD5',
  brand5Mid2: '#E7EBE6',
  brand5Light: '#F5F7F5',
} as const;
