import { Spin as Hamburger } from 'hamburger-react';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { brand1DarkAlt } from '../../../../design/colors/brand';
import { breakpointMedium } from '../../../../design/responsive/breakpoints';
import { WithTestId } from '../../../../utlityTypes';

/* eslint-disable @typescript-eslint/ban-types */

const BurgerButtonElement = styled.button`
  cursor: pointer;
  .hamburger-react {
    &:hover {
      transition-property: background;
      * {
        background: ${brand1DarkAlt}!important;
      }
    }
  }
  ${breakpointMedium(css`
    display: none;
  `)}
`;

type BurgerProps = { onClick?: () => void; toggled?: boolean } & WithTestId;
/**
 * Mobile burger menu button.
 */
export const BurgerButton: FC<BurgerProps> = ({ onClick, 'data-testid': testId, toggled }) => (
  <BurgerButtonElement onClick={onClick} data-testid={testId}>
    <Hamburger rounded size={24} toggled={toggled} />
  </BurgerButtonElement>
);
