import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { brand1, brand3, brand4 } from '../../../design/colors/brand';
import { white } from '../../../design/colors/greyscale';
import { ButtonLink } from '../../../design/components/button/Button';
import { Panel } from '../../../design/containers/panel/Panel';
import BaseColumnSmallRowContainer from '../../../design/containers/responsive/BaseColumnSmallRowContainer';
import { breakpointSmall, breakpointMedium } from '../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../design/spacing/Spacing';
import { Heading4, BodySpan } from '../../../design/typography/Typography';
import { WithClassName } from '../../../utlityTypes';
import List from '../list/List';
import Price from './Price';

const CardsContainer = styled(BaseColumnSmallRowContainer)`
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: ${spacings['24px']};
  width: 100%;

  ${breakpointMedium(css`
    flex-direction: row;
  `)};
`;

const getCardPanelStyle = (index: number) => {
  const style = [
    { color: white, background: `${brand3}d6` },
    { color: brand4, background: white, 'border-top': `8px solid ${brand1}` },
    { color: white, background: 'linear-gradient(180deg,#2b3d58fa 0%,#151f2e 100%)' },
  ];
  return style[index];
};

type OpacityOption = { index: number };
const CardPanel = styled(Panel)<OpacityOption>`
  padding: ${spacings['32px']};
  flex: 1;
  align-self: center;
  max-width: 416px;
  min-width: 224px;
  align-self: center;
  height: 585px;
  ${({ index }) => getCardPanelStyle(index)};

  ${breakpointSmall(css`
    align-self: center;
    padding: ${spacings['48px']};
  `)};
`;
CardPanel.defaultProps = { shadow: 'bigShadowStyles', padding: '32px', borderRadius: '12px' };

const PillTag = styled(Panel).attrs({ background: 'brand3Solid' })`
  width: fit-content;
  min-width: 110px;
  padding: ${spacings['12px']};
  height: 30px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TwoColumnContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const HalfWidthButton = styled(ButtonLink)`
  width: fit-content;
`;

export type PricingItem = Queries.PrismicPricingDefaultItem;
export type ItemProps = PricingItem;

const Heading: FC<Pick<ItemProps, 'heading' | 'tagText'>> = ({ heading, tagText }) => (
  <TwoColumnContainer>
    <Spacing right="12px">
      <Heading4 textAlign="left">{heading}</Heading4>
    </Spacing>
    {tagText && (
      <PillTag>
        <BodySpan type="bodyXSRegular">{tagText}</BodySpan>
      </PillTag>
    )}
  </TwoColumnContainer>
);

export type ItemPricingProps = { items: Array<Queries.PrismicPricingDefaultItem> } & WithClassName;
/**
 * Renders the three pricing columns for Pricing slice.
 */
const PricingColumns: FC<ItemPricingProps> = ({ items, className }) => {
  return (
    <Spacing bottom="64px">
      <CardsContainer className={className}>
        {items?.map((item, index) => {
          const {
            heading,
            price,
            pricingPeriod,
            ctaButtonText,
            ctaButtonLink,
            pricingSubtext,
            itemHeading,
            tagText,
            ...listItems
          } = item;
          const listedItems = Object.values(listItems);
          return (
            <CardPanel key={heading} index={index}>
              <>
                <Heading heading={heading} tagText={tagText} />
                <Price price={price} pricingPeriod={pricingPeriod} pricingSubtext={pricingSubtext} />
                <Spacing top="24px">
                  <HalfWidthButton size="medium" styleType="primary" link={ctaButtonLink}>
                    {ctaButtonText}
                  </HalfWidthButton>
                </Spacing>
                <Spacing top="24px">
                  <List className={className} heading={itemHeading} items={listedItems} reverse={index % 2 !== 0} />
                </Spacing>
              </>
            </CardPanel>
          );
        })}
      </CardsContainer>
    </Spacing>
  );
};

export default PricingColumns;
