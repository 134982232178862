import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { Panel } from '../../design/containers/panel/Panel';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { IconType } from '../../design/icons/Icon';
import { breakpointMedium, breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import Heading1 from '../../design/typography/Heading1';
import { BodyP } from '../../design/typography/Typography';
import { WithClassName } from '../../utlityTypes';
import ChiliPiperForm from './chiliPiperForm/ChiliPiperForm';
import HubspotForm, { HubspotProps } from './hubspotForm/HubspotForm';
import InformationPanel from './informationPanel/InformationPanel';

const HeadingContainer = styled.div`
  padding-top: ${spacings['64px']};
  max-width: 814px;

  ${breakpointSmall(css`
    padding-top: ${spacings['100px']};
  `)}
`;

const PanelContainer = styled(Panel)`
  display: flex;
  flex-direction: column;
  gap: ${spacings['24px']};
  align-items: center;
  max-width: 1286px;
  padding-bottom: ${spacings['32px']};
  margin-top: ${spacings['48px']};

  ${breakpointMedium(css`
    flex-direction: row-reverse;
    padding: ${spacings['32px']};
    align-items: center;
    justify-content: center;
    margin-top: ${spacings['12px']};

    border-top-left-radius: ${spacings['24px']};
    border-top-right-radius: ${spacings['24px']};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `)}
`;

const FormContainer = styled(Panel)`
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;

  ${breakpointMedium(css`
    border-radius: 24px;
    height: 100%;
    width: auto;
    max-width: 550px;
  `)}
`;

export type IconProps = { image: IconType | string; text: string };
export type FormProps = {
  subHeading?: string;
  icons?: IconProps[];
  partnershipHeading?: string;
  hubspot?: HubspotProps;
  logos?: Array<Queries.PrismicDemoDefaultItem>;
};
export type DemoProps = {
  heading: PrismicRichTextType;
  text?: string;
  form: FormProps;
} & WithClassName;

const Heading: FC<Pick<DemoProps, 'heading' | 'text'>> = ({ heading, text }) => (
  <HeadingContainer>
    <Heading1 textAlign="center">{heading}</Heading1>
    <Spacing top={{ base: '24px' }} bottom={{ base: 'none', breakpointMedium: '48px' }}>
      <BodyP type="bodyLRegular" textAlign="center">
        {text}
      </BodyP>
    </Spacing>
  </HeadingContainer>
);

/**
 * Renders Demo slice.
 */
const Demo: FC<DemoProps> = ({ className, heading, text, form }) => {
  const isChiliPiperForm = form.hubspot?.formType === 'Chili Piper Form';
  return (
    <SliceContainer className={className} background="brand5ExpressiveWaveLime">
      <Heading heading={heading} text={text} />
      <PanelContainer background={{ base: 'transparent', breakpointMedium: 'brand3Solid' }}>
        <FormContainer>
          {isChiliPiperForm ? (
            <ChiliPiperForm
              id={form.hubspot?.id || ''}
              link={form.hubspot?.link || null}
              trackingEvent={form.hubspot?.trackingEvent || ''}
            />
          ) : (
            <HubspotForm
              id={form.hubspot?.id || ''}
              link={form.hubspot?.link || null}
              trackingEvent={form.hubspot?.trackingEvent || ''}
            />
          )}
        </FormContainer>
        <InformationPanel
          subHeading={form.subHeading}
          icons={form.icons}
          partnershipHeading={form.partnershipHeading}
          logos={form.logos}
          isChiliPiperForm={isChiliPiperForm}
        />
      </PanelContainer>
    </SliceContainer>
  );
};

export default Demo;
