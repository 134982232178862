import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import TwoColumnContent from '../TwoColumnContent';

type TwoColumnContentDataProps = SliceProps<
  'twoColumnContent',
  SliceVariation<'default', Queries.PrismicTwoColumnContentDefaultPrimary>
> &
  WithClassName;
/**
 * TwoColumnContent data component: specifies the fragment and passes the appropriate data to the TwoColumnContent component.
 */
const TwoColumnContentData: FC<TwoColumnContentDataProps> = ({ className, slice }) => (
  <TwoColumnContent
    className={className}
    topLeftCard={{
      heading: slice.primary.topLeftCardHeading || '',
      text: slice.primary.topLeftCardText || '',
    }}
    topRightCard={{
      quote: slice.primary.topRightCardQuote || '',
      author: slice.primary.topRightCardAuthor || '',
      role: slice.primary.topRightCardRole || '',
      company: slice.primary.topRightCardCompany || '',
    }}
    middleRightCard={{
      heading: slice.primary.middleRightCardHeading || '',
      text: slice.primary.middleRightCardText || '',
    }}
    middleLeftCard={{
      quote: slice.primary.middleLeftCardQuote || '',
      author: slice.primary.middleLeftCardAuthor || '',
      role: slice.primary.middleLeftCardRole || '',
      company: slice.primary.middleLeftCardCompany || '',
    }}
    image={slice.primary.image}
    bottomLeftCard={{
      heading: slice.primary.bottomLeftCardHeading || '',
      text: slice.primary.bottomLeftCardText || '',
    }}
    bottomRightCard={{
      quote: slice.primary.bottomRightCardQuote || '',
      author: slice.primary.bottomRightCardAuthor || '',
      role: slice.primary.bottomRightCardRole || '',
      company: slice.primary.bottomRightCardCompany || '',
    }}
  />
);

export default TwoColumnContentData;

/**
 * @todo Convert card headings to rich text.
 */
export const query = graphql`
  fragment TwoColumnContent on PrismicTwoColumnContentDefault {
    primary {
      bottomLeftCardHeading
      bottomLeftCardText
      bottomRightCardAuthor
      bottomRightCardCompany
      bottomRightCardQuote
      bottomRightCardRole
      image {
        ...Image
      }
      middleLeftCardAuthor
      middleLeftCardCompany
      middleLeftCardQuote
      middleLeftCardRole
      middleRightCardHeading
      middleRightCardText
      topLeftCardHeading
      topLeftCardText
      topRightCardAuthor
      topRightCardCompany
      topRightCardQuote
      topRightCardRole
    }
  }
`;
