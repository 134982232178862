import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Pricing from '../Pricing';

type PricingProps = SliceProps<
  'pricing',
  SliceVariation<'default', Queries.PrismicPricingDefaultPrimary, Queries.PrismicPricingDefaultItem>
> &
  WithClassName;

/**
 * Pricing data component: specifies the fragment and passes the appropriate data to the Pricing component.
 */
const PricingData: FC<PricingProps> = ({ className, slice }) => {
  return (
    <Pricing
      className={className}
      heading={slice.primary?.heading}
      text={slice.primary?.text || ''}
      terms={slice.primary?.termsAndConditions || ''}
      bottomCard={{
        heading: slice.primary?.bottomHeading || '',
        subHeading: slice.primary?.bottomSubheading || '',
        price: slice.primary?.bottomPrice || '',
        pricingPeriod: slice.primary?.bottomPricingPeriod || '',
        ctaButton: { text: slice.primary?.bottomCtaButtonText || '', link: slice.primary?.bottomCtaButtonLink },
        listItems: {
          firstItem: slice.primary?.bottomFirstListItem || '',
          secondItem: slice.primary?.bottomSecondListItem || '',
          thirdItem: slice.primary?.bottomThirdListItem || '',
          fourthItem: slice.primary?.bottomFourthListItem || '',
          fifthItem: slice.primary?.bottomFifthListItem || '',
        },
      }}
      items={slice.items || []}
    />
  );
};

export default PricingData;

export const query = graphql`
  fragment Pricing on PrismicPricingDefault {
    primary {
      heading {
        ...RichText
      }
      text
      bottomHeading
      bottomSubheading
      bottomPrice
      bottomPricingPeriod
      bottomCtaButtonText
      bottomCtaButtonLink {
        ...PrismicLink
      }
      bottomFirstListItem
      bottomSecondListItem
      bottomThirdListItem
      bottomFourthListItem
      bottomFifthListItem
      termsAndConditions
    }
    items {
      heading
      tagText
      price
      pricingPeriod
      pricingSubtext
      ctaButtonText
      ctaButtonLink {
        ...PrismicLink
      }
      itemHeading
      firstListItem
      secondListItem
      thirdListItem
      fourthListItem
      fifthListItem
    }
  }
`;
