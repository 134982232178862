import { css } from 'styled-components';
import { brand1, brand1Dark, brand4 } from '../../../../../../colors/brand';
import { grey400, grey700 } from '../../../../../../colors/greyscale';
import { disabledStyle } from '../../../../../../utils/style';

export const primaryDisabledStyles = disabledStyle(css`
  background-color: ${grey400};
  border-color: ${grey400};
  color: ${grey700};
`);

/**
 * Generates the primary button type style.
 */
export const primaryTypeStyle = () => css`
  background-color: ${brand1};
  border-color: ${brand1Dark};
  color: ${brand4};

  &:hover {
    background-color: ${brand1Dark};
  }

  ${primaryDisabledStyles}
`;
