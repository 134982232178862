import React, { FC, ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../components/image/prismicImage/PrismicImage';
import { Panel } from '../../design/containers/panel/Panel';
import { breakpointLarge, breakpointSmall } from '../../design/responsive/breakpoints';
import { spacings } from '../../design/spacing/Spacing';
import ContentInfoItem from './ContentInfoItem';

const ColumnContainer = styled(Panel).attrs({ background: 'Brand3SolidExpressiveWave' })`
  max-width: 1286px;
  margin-top: ${spacings['48px']};
  padding: ${spacings['32px']};
  display: grid;
  border-radius: 24px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: start;
  overflow: unset;

  ${breakpointSmall(css`
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    grid-template-columns: 40% 60%;

    padding: ${spacings['12px']} ${spacings['48px']} ${spacings['48px']} ${spacings['48px']};
  `)}
`;

const Column = styled.div`
  ${breakpointSmall(css`
    flex: 1;
  `)}
`;

const imageOffset = 50;
const LeftContent = styled(Column)`
  margin-top: -${imageOffset + 10}%;
  margin-right: ${spacings['12px']};

  @media (min-width: 380px) {
    margin-top: -160px;
  }

  @media (min-width: 480px) {
    margin-top: -160px;
  }

  ${breakpointSmall(css`
    margin-top: 0;
  `)}
`;

const RightContent = styled(Column)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  order: -1;
  top: -${imageOffset}%;

  ${breakpointSmall(css`
    position: static;
    order: 0;
    margin-left: ${spacings['12px']};
  `)}

  ${breakpointLarge(css`
    justify-content: center;
    height: 100%;
  `)}
`;

const Image = styled(PrismicImage)`
  width: 130%;
  max-width: 320px;
  top: 10px;

  ${breakpointSmall(css`
    max-width: none;
    width: 100%;
  `)}
`;

export type BottomContentProps = {
  image: ComponentProps<typeof PrismicImage>['image'];
  items?: Array<Queries.PrismicContentInfoBlockDefaultItem>;
};
/**
 * Renders bottom info block for ContentInfoBlock slice.
 */
const BottomInfoBlock: FC<BottomContentProps> = ({ items, image }) => (
  <ColumnContainer>
    <LeftContent>
      {items?.map((item, key) => (
        <ContentInfoItem
          key={key}
          lastContent={key + 1 === items.length}
          heading={item.heading}
          text={item.text}
          ctaText={item.ctaButtonText}
          ctaLink={item.ctaLink}
        />
      ))}
    </LeftContent>
    <RightContent>
      <Image image={image} />
    </RightContent>
  </ColumnContainer>
);

export default BottomInfoBlock;
