import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Background } from '../../../design/containers/panel/Panel';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import TwoColumnContentImage from '../TwoColumnContentImage';

const getBackgroundForVariation = (variation: TwoColumnContentImageDataProps['slice']['variation']) => {
  const variationBackgroundMap: Record<typeof variation, Background> = {
    whiteSolid: 'whiteSolid',
    grey200Solid: 'grey200Solid',
    brand2Solid: 'brand2Solid',
    brand2SolidStripedCurveLeft: 'brand2SolidStripedCurveLeft',
    brand3Solid: 'brand3Solid',
    brand3Gradient: 'brand3Gradient',
    brand3GradientStripedCurve: 'brand3GradientStripedCurve',
    brand4Gradient: 'brand4Gradient',
    brand4GradientWeelLogo: 'brand4GradientWeelLogo',
    brand4GradientWeelLogoLeft: 'brand4GradientWeelLogoLeft',
    brand5Solid: 'brand5Solid',
    brand5SolidCardsLeft: 'brand5SolidCardsLeft',
  };
  return variationBackgroundMap[variation];
};

type TwoColumnContentImageDataProps = SliceProps<
  'two_column_content_image',
  | SliceVariation<'whiteSolid', Queries.PrismicTwoColumnContentImageWhiteSolidPrimary>
  | SliceVariation<'grey200Solid', Queries.PrismicTwoColumnContentImageGrey200SolidPrimary>
  | SliceVariation<'brand2Solid', Queries.PrismicTwoColumnContentImageBrand2SolidPrimary>
  | SliceVariation<
      'brand2SolidStripedCurveLeft',
      Queries.PrismicTwoColumnContentImageBrand2SolidStripedCurveLeftPrimary
    >
  | SliceVariation<'brand3Solid', Queries.PrismicTwoColumnContentImageBrand3SolidPrimary>
  | SliceVariation<'brand3Gradient', Queries.PrismicTwoColumnContentImageBrand3GradientPrimary>
  | SliceVariation<'brand3GradientStripedCurve', Queries.PrismicTwoColumnContentImageBrand3GradientStripedCurvePrimary>
  | SliceVariation<'brand4Gradient', Queries.PrismicTwoColumnContentImageBrand4GradientPrimary>
  | SliceVariation<'brand4GradientWeelLogo', Queries.PrismicTwoColumnContentImageBrand4GradientWeelLogoPrimary>
  | SliceVariation<'brand4GradientWeelLogoLeft', Queries.PrismicTwoColumnContentImageBrand4GradientWeelLogoLeftPrimary>
  | SliceVariation<'brand5Solid', Queries.PrismicTwoColumnContentImageBrand5SolidPrimary>
  | SliceVariation<'brand5SolidCardsLeft', Queries.PrismicTwoColumnContentImageBrand5SolidCardsLeftPrimary>
> &
  WithClassName;
/**
 * TwoColumnContentImage data component: specifies the fragment and passes the appropriate data to the TwoColumnContentImage component.
 */
const TwoColumnContentImageData: FC<TwoColumnContentImageDataProps> = ({ className, slice }) => {
  return (
    <TwoColumnContentImage
      className={className}
      heading={slice.primary?.heading}
      text={slice.primary?.text || ''}
      primaryCta={{ text: slice.primary?.primaryCtaButtonText || '', link: slice.primary?.primaryCtaLink }}
      secondaryCta={{ text: slice.primary?.secondaryCtaButtonText || '', link: slice.primary?.secondaryCtaLink }}
      desktopImage={slice.primary?.imageDesktop}
      mobileImage={slice.primary?.imageMobile}
      contentSide={slice.primary?.layout === 'Image Left - Content Right' ? 'right' : 'left'}
      backgroundType={getBackgroundForVariation(slice.variation)}
    />
  );
};

export default TwoColumnContentImageData;

export const query = graphql`
  fragment TwoColumnContentImageBrand3GradientStripedCurve on PrismicTwoColumnContentImageBrand3GradientStripedCurve {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
    }
  }

  fragment TwoColumnContentImageBrand3Gradient on PrismicTwoColumnContentImageBrand3Gradient {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
    }
  }

  fragment TwoColumnContentImageBrand4GradientWeelLogo on PrismicTwoColumnContentImageBrand4GradientWeelLogo {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
    }
  }

  fragment TwoColumnContentImageBrand2Solid on PrismicTwoColumnContentImageBrand2Solid {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
    }
  }

  fragment TwoColumnContentImageBrand4Gradient on PrismicTwoColumnContentImageBrand4Gradient {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
    }
  }

  fragment TwoColumnContentImageWhiteSolid on PrismicTwoColumnContentImageWhiteSolid {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
    }
  }

  fragment TwoColumnContentImageBrand3Solid on PrismicTwoColumnContentImageBrand3Solid {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
    }
  }

  fragment TwoColumnContentImageBrand5Solid on PrismicTwoColumnContentImageBrand5Solid {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
    }
  }

  fragment TwoColumnContentImageBrand5SolidCardsLeft on PrismicTwoColumnContentImageBrand5SolidCardsLeft {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
    }
  }

  fragment TwoColumnContentImageBrand4GradientWeelLogoLeft on PrismicTwoColumnContentImageBrand4GradientWeelLogoLeft {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
    }
  }

  fragment TwoColumnContentImageBrand2SolidStripedCurve on PrismicTwoColumnContentImageBrand2SolidStripedCurveLeft {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
    }
  }

  fragment TwoColumnContentImageGrey200Solid on PrismicTwoColumnContentImageGrey200Solid {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
    }
  }

  fragment TwoColumnContentImage on PrismicSliceType {
    ...TwoColumnContentImageBrand3GradientStripedCurve
    ...TwoColumnContentImageBrand3Gradient
    ...TwoColumnContentImageBrand4GradientWeelLogo
    ...TwoColumnContentImageBrand2Solid
    ...TwoColumnContentImageBrand4Gradient
    ...TwoColumnContentImageWhiteSolid
    ...TwoColumnContentImageBrand3Solid
    ...TwoColumnContentImageBrand5Solid
    ...TwoColumnContentImageBrand5SolidCardsLeft
    ...TwoColumnContentImageBrand4GradientWeelLogoLeft
    ...TwoColumnContentImageBrand2SolidStripedCurve
    ...TwoColumnContentImageGrey200Solid
  }
`;
