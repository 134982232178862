import React, { PropsWithChildren, FC } from 'react';
import styled, { css } from 'styled-components';
import { blogContentZindex } from '../../../components/zindex';
import { brand5 } from '../../../design/colors/brand';
import { blogWysiwygShadow } from '../../../design/colors/shadows';
import { OnVisible } from '../../../design/components/onVisible/OnVisible';
import { Panel } from '../../../design/containers/panel/Panel';
import { createBreakpoint } from '../../../design/responsive/breakpointUtils';
import { breakpointMedium, breakpointXSmall } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';

const pageGutter = '24px';
export const topOffsetAmount = 108;

const Container = styled(Panel)`
  position: relative;
  top: -${topOffsetAmount}px;
  margin: 0 auto;
  position: relative;
  max-width: calc(100% - ${pageGutter});
  padding: ${spacings['16px']} 0;
  margin-bottom: ${spacings['24px']};
  ${blogContentZindex};
  ${blogWysiwygShadow};
  > div > div {
    padding-top: 0;
    padding-bottom: 0;
  }
  ${breakpointXSmall(
    css`
      padding: ${spacings['32px']} 24px;
      max-width: calc(100% - 48px);
    `
  )};
  ${breakpointMedium(
    css`
      padding: ${spacings['64px']} 76px;
    `
  )};
  ${createBreakpoint(1400)(
    css`
      max-width: 1280px;
    `
  )};
`;

Container.defaultProps = { background: 'whiteSolid', borderRadius: '24px' };

const Background = styled(Panel)`
  background: ${brand5};
  overflow: visible;
`;
Background.defaultProps = { borderRadius: '24px' };

/**
 * Renders Container for blog content.
 */
// eslint-disable-next-line react/prop-types
const ContentWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Background>
      <OnVisible fadeUp threshold={0.01}>
        <Container>{children}</Container>
      </OnVisible>
    </Background>
  );
};

export default ContentWrapper;
