import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../../components/image/prismicImage/PrismicImage';
import { brand2 } from '../../../design/colors/brand';
import { Divider } from '../../../design/components/divider/Divider';
import FiveStars from '../../../design/components/fiveStars/FiveStars';
import { getBackgroundStyles } from '../../../design/containers/panel/Panel';
import Icon, { IconType } from '../../../design/icons/Icon';
import { breakpointMedium, breakpointSmall, breakpointXSmall } from '../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../design/spacing/Spacing';
import { BodySpan, Heading3 } from '../../../design/typography/Typography';
import { FormProps, IconProps } from '../Demo';

const Column = styled.div`
  ${breakpointSmall(css`
    flex: 1;
  `)}
`;
const Container = styled(Column)`
  ${getBackgroundStyles('brand3Solid')}
  border-radius: 24px;
  padding: ${spacings['24px']};

  ${breakpointXSmall(css`
    padding: ${spacings['32px']};
  `)}

  ${breakpointSmall(css`
    padding: ${spacings['64px']};
  `)}

  ${breakpointMedium(css`
    background: transparent;
    padding: ${spacings['24px']};
  `)}
`;

const IconContainer = styled.div`
  border-radius: ${spacings['100px']};
  min-width: 60px;
  min-height: 60px;
  display: grid;
  justify-content: center;
  align-items: center;
  background: ${brand2};
`;

const IconRowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings['12px']};
`;

const IconSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings['24px']};

  @media (min-width: 539px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const LogoImage = styled(PrismicImage)`
  height: 100%;
`;

const LogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: ${spacings['32px']};
  row-gap: ${spacings['24px']};
  padding-top: ${spacings['32px']};
`;

const RatingsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacings['12px']};
`;

const BottomLeftContainer = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
`;

const IconSection: FC<IconProps> = ({ image, text }) => (
  <IconRowContainer>
    {image && (
      <IconContainer>
        <Icon type={image as IconType} />
      </IconContainer>
    )}
    <BodySpan type="bodyLRegular">{text}</BodySpan>
  </IconRowContainer>
);
const TopSection: FC<Pick<FormProps, 'subHeading' | 'icons'>> = ({ subHeading, icons }) => (
  <div>
    <Spacing top="24px" bottom="48px">
      <Heading3 textAlign="left">{subHeading}</Heading3>
    </Spacing>
    <IconSectionContainer>
      {icons?.map(({ image, text }) => (
        <IconSection key={image} image={image} text={text} />
      ))}
    </IconSectionContainer>
  </div>
);

const Ratings: FC = () => (
  <Spacing top="48px">
    <RatingsContainer>
      <FiveStars />
      <StaticImage style={{ height: '100%' }} src="../../../images/xero-logo-white.png" alt="xero logo" />
    </RatingsContainer>
  </Spacing>
);
const BottomSection: FC<Pick<InformationPanelProps, 'partnershipHeading' | 'logos' | 'isChiliPiperForm'>> = ({
  partnershipHeading,
  logos,
  isChiliPiperForm,
}) => (
  <BottomLeftContainer width={isChiliPiperForm ? 100 : 80}>
    <Heading3 textAlign="left">{partnershipHeading}</Heading3>
    <LogosContainer>
      {logos?.map((logo, index) => (
        <LogoImage key={index} image={logo.logo} />
      ))}
    </LogosContainer>
    <Ratings />
  </BottomLeftContainer>
);

type InformationPanelProps = { isChiliPiperForm: boolean } & FormProps;
/**
 * Renders the information for Demo slice.
 */
const InformationPanel: FC<InformationPanelProps> = ({
  subHeading,
  icons,
  partnershipHeading,
  logos,
  isChiliPiperForm,
}) => (
  <Container>
    <TopSection subHeading={subHeading} icons={icons} />
    <Spacing top="64px" bottom="64px">
      <Divider styleType="brand2MidOpacity0point3" />
    </Spacing>
    <BottomSection partnershipHeading={partnershipHeading} logos={logos} isChiliPiperForm={isChiliPiperForm} />
  </Container>
);

export default InformationPanel;
