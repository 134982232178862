import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import ThreeColumnFiveCards from '../ThreeColumnFiveCards';

type ThreeColumnFiveCardsProps = SliceProps<
  'threeColumnFiveCards',
  SliceVariation<'default', Queries.PrismicThreeColumnFiveCardsDefaultPrimary>
> &
  WithClassName;

/**
 * Renders ThreeColumnFiveCard slice.
 */
const ThreeColumnFiveCardsData: FC<ThreeColumnFiveCardsProps> = ({ slice }) => {
  return (
    <ThreeColumnFiveCards
      leftCard={{
        title: slice.primary.leftCardTitle || '',
        text: slice.primary.leftCardText || '',
        desktopImage: slice.primary.leftCardImageDesktop,
        mobileImage: slice.primary.leftCardImageMobile,
      }}
      middleTopCard={{
        title: slice.primary.middleTopCardTitle || '',
        text: slice.primary.middleTopCardText || '',
        icon: slice.primary.middleTopCardIcon || null,
        cta: { text: slice.primary.middleTopCardButtonText || '', link: slice.primary.middleTopCardButtonLink },
      }}
      middleBottomCard={{
        title: slice.primary.middleBottomCardTitle || '',
        text: slice.primary.middleBottomCardText || '',
        icon: slice.primary.middleBottomCardIcon || null,
        cta: { text: slice.primary.middleBottomCardButtonText || '', link: slice.primary.middleBottomCardButtonLink },
      }}
      topRightCard={{
        title: slice.primary.topRightCardTitle || '',
        text: slice.primary.topRightCardText || '',
        icon: slice.primary.topRightCardIcon || null,
        cta: { text: slice.primary.topRightCardButtonText || '', link: slice.primary.topRightCardButtonLink },
      }}
      bottomRightCard={{
        title: slice.primary.bottomRightCardTitle || '',
        text: slice.primary.bottomRightCardText || '',
        icon: slice.primary.bottomRightCardIcon || null,
        cta: { text: slice.primary.bottomRightCardButtonText || '', link: slice.primary.bottomRightCardButtonLink },
      }}
      backgroundName="brand3GradientStripedCurve"
    />
  );
};

export default ThreeColumnFiveCardsData;

export const query = graphql`
  fragment ThreeColumnFiveCardsDefault on PrismicThreeColumnFiveCardsDefault {
    variation
    primary {
      bottomRightCardButtonText
      bottomRightCardIcon
      bottomRightCardText
      bottomRightCardTitle
      bottomRightCardButtonLink {
        ...PrismicLink
      }
      leftCardText
      leftCardTitle
      leftCardImageDesktop {
        ...Image
      }
      leftCardImageMobile {
        ...Image
      }
      middleBottomCardButtonText
      middleBottomCardIcon
      middleBottomCardText
      middleBottomCardTitle
      middleTopCardButtonText
      middleTopCardIcon
      middleTopCardText
      middleTopCardTitle
      topRightCardTitle
      topRightCardText
      topRightCardIcon
      topRightCardButtonText
      topRightCardButtonLink {
        ...PrismicLink
      }
      middleTopCardButtonLink {
        ...PrismicLink
      }
      middleBottomCardButtonLink {
        ...PrismicLink
      }
    }
  }

  fragment ThreeColumnFiveCards on PrismicSliceType {
    ...ThreeColumnFiveCardsDefault
  }
`;
