import React, { FC, PropsWithChildren, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { SEO, SEOProps } from '../components/SEO/SEO';
import GoogleTagManager from '../components/analytics/GoogleTagManager';
import Segment from '../components/analytics/Segment';
import FooterData from '../components/footer/data/Footer.queryData';
import { generateStylesForHeaderHeightAtBreakpoints } from '../components/header/components/containers/HeaderContainer';
import HeaderData from '../components/header/data/Header.queryData';
import { pagePadding } from '../templates/page/styles';
import { ChangeDropdownStatus, HeaderContext, OpenDropdowns } from './HeaderContext';

type ContentContainerProps = {
  noContentPadding?: boolean;
};

const PageContainerRoot = styled.main``;
const ContentContainer = styled.div<ContentContainerProps>`
  ${(props) =>
    !props.noContentPadding &&
    css`
      ${pagePadding}
      ${generateStylesForHeaderHeightAtBreakpoints((headerHeight) => `margin-top: ${headerHeight};`)}
    `}
`;

type Props = { headerId?: string; footerId?: string; seoProps?: SEOProps; noContentPadding?: boolean };
/**
 * Container that provides common spacing for all pages.
 */
const PageContainer: FC<PropsWithChildren<Props>> = ({ headerId, footerId, children, seoProps, noContentPadding }) => {
  const [openDropdowns, setOpenDropdowns] = useState<OpenDropdowns>({});
  const closeAllDropdowns = () => setOpenDropdowns({});
  const changeDropdownStatus: ChangeDropdownStatus = useCallback(
    (headingName, open) => setOpenDropdowns({ [headingName]: !!open }),
    []
  );

  return (
    <PageContainerRoot onClick={closeAllDropdowns} data-testid="page-container">
      <SEO {...seoProps} />
      <GoogleTagManager />
      <Segment />
      {headerId && (
        <HeaderContext.Provider value={{ dropdownsStatus: openDropdowns, changeDropdownStatus }}>
          <HeaderData id={headerId} />
        </HeaderContext.Provider>
      )}
      <ContentContainer noContentPadding={noContentPadding}>
        {children}
        {footerId && <FooterData id={footerId} />}
      </ContentContainer>
    </PageContainerRoot>
  );
};

export default PageContainer;
