/* eslint-disable react/no-unescaped-entities */
import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../../components/image/prismicImage/PrismicImage';
import { breakpointSmall, breakpointXSmall } from '../../../design/responsive/breakpoints';

const FrameContainer = styled.div`
  overflow: hidden;
  position: relative;
  grid-area: image;
  max-height: 216px;
  max-width: 272px;
  ${breakpointXSmall(css`
    transform: scale(1.25);
  `)};
  ${breakpointSmall(css`
    transform: scale(1);
  `)};
`;

const CardImage = styled(PrismicImage)`
  && {
    position: absolute;
  }
  left: 25px;
  top: 0;
  max-width: 250px;
`;

type FrameProps = {
  image?: ComponentProps<typeof PrismicImage>['image'];
};

/**
 * Renders CardsFrame comp.
 */
const CardsFrame: FC<FrameProps> = ({ image }) => (
  <FrameContainer>
    <svg width="349" height="304" viewBox="0 0 349 304" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7">
        <rect
          x="0"
          y="68.6972"
          width="219.993"
          height="156.565"
          rx="8.23881"
          transform="rotate(-17.6486 0 68.6972)"
          fill="#296366"
        />
      </g>
      <rect
        x="15"
        y="36.4324"
        width="219.993"
        height="156.565"
        rx="8.23881"
        transform="rotate(-9.00488 15 36.4324)"
        fill="#407A7D"
      />
      <defs>
        <clipPath id="hex-mask">
          <rect x="25" y="2" width="219.993" height="156.565" rx="8.23881" fill="#F5F5F5" />
        </clipPath>
      </defs>
    </svg>
    {image && <CardImage image={image} style={{ clipPath: 'url(#hex-mask)' }} />}
  </FrameContainer>
);

export default CardsFrame;
