import React, { FC } from 'react';
import styled from 'styled-components';
import { LinkType } from '../../components/link/types';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { grey700 } from '../../design/colors/greyscale';
import { ButtonLink } from '../../design/components/button/Button';
import { OnVisible } from '../../design/components/onVisible/OnVisible';
import { Background } from '../../design/containers/panel/Panel';
import TwoCtaPlacementContainer from '../../design/containers/placements/TwoCtaPlacementContainer';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import Spacing from '../../design/spacing/Spacing';
import Heading2 from '../../design/typography/Heading2';
import { BodyP } from '../../design/typography/Typography';
import { WithClassName } from '../../utlityTypes';

const StyledSubText = styled(BodyP)`
  color: ${grey700};
`;

type BottomCtaProps = {
  background: Background;
  heading: PrismicRichTextType;
  text?: string;
  subtext?: string;
  primaryCta: LinkType;
  secondaryCta: LinkType;
} & WithClassName;
/**
 * Renders BottomCta slice.
 */
const BottomCta: FC<BottomCtaProps> = ({
  className,
  background = 'whiteSolid',
  heading,
  text,
  subtext,
  primaryCta,
  secondaryCta,
}) => (
  <SliceContainer className={className} background={background}>
    <CenteredSliceContentContainer>
      <OnVisible fadeUp>
        <Heading2>{heading}</Heading2>
      </OnVisible>
      {text && (
        <Spacing top="24px">
          <OnVisible fadeUp>
            <BodyP type="bodyLRegular" textAlign="center">
              {text}
            </BodyP>
          </OnVisible>
        </Spacing>
      )}
      <Spacing top="24px">
        <OnVisible fadeUp>
          <TwoCtaPlacementContainer
            cta1Placement={
              <ButtonLink styleType="primary" link={primaryCta.link}>
                {primaryCta.text}
              </ButtonLink>
            }
            cta2Placement={
              <ButtonLink styleType="secondary" link={secondaryCta.link}>
                {secondaryCta.text}
              </ButtonLink>
            }
          />
        </OnVisible>
      </Spacing>
      <OnVisible fadeUp>
        <Spacing top="24px">
          <StyledSubText type="bodyMRegular" textAlign="center">
            {subtext}
          </StyledSubText>
        </Spacing>
      </OnVisible>
    </CenteredSliceContentContainer>
  </SliceContainer>
);

export default BottomCta;
