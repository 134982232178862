import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../components/image/prismicImage/PrismicImage';
import { LinkType } from '../../components/link/types';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { ButtonLink } from '../../design/components/button/Button';
import { Background } from '../../design/containers/panel/Panel';
import * as backgrounds from '../../design/containers/panel/backgrounds';
import TwoCtaPlacementContainer from '../../design/containers/placements/TwoCtaPlacementContainer';
import { Side, SideSliceContentContainer } from '../../design/containers/sliceContainer/SideSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing from '../../design/spacing/Spacing';
import Heading2 from '../../design/typography/Heading2';
import { BodyP } from '../../design/typography/Typography';
import { WithClassName } from '../../utlityTypes';

const ContainImage = styled(PrismicImage)``;
ContainImage.defaultProps = { objectFit: 'contain' };

const RelativePositionFlex1Wrapper = styled.div`
  position: relative;
  flex: 1 0 0;
`;
const AbsolutePositionContainImage = styled(ContainImage)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;
type DesktopImageProps = ComponentProps<typeof PrismicImage>;
const DesktopImage: FC<DesktopImageProps> = ({ className, ...props }) => (
  <RelativePositionFlex1Wrapper className={className}>
    <AbsolutePositionContainImage {...props} />
  </RelativePositionFlex1Wrapper>
);
DesktopImage.defaultProps = { visibility: { base: 'hide', breakpointMedium: 'show' } };

const MobileImage = styled(ContainImage)`
  align-self: center;
`;
MobileImage.defaultProps = { visibility: { base: 'show', breakpointMedium: 'hide' } };

const ButtonsPlacement = styled(TwoCtaPlacementContainer)`
  align-self: center;

  ${breakpointSmall(css`
    align-self: flex-start;
  `)}
`;

type SideSliceContentProps = { children: React.ReactNode } & Pick<
  TwoColumnContentImageProps,
  'contentSide' | 'desktopImage'
>;
const SideSliceContent: FC<SideSliceContentProps> = ({ contentSide, desktopImage, children }) => (
  <SideSliceContentContainer
    verticalPadding="none"
    side={contentSide}
    otherSideContent={<DesktopImage image={desktopImage} />}
  >
    <Spacing
      top={{ base: '24px', breakpointSmall: '100px', breakpointLarge: '144px' }}
      bottom={{ base: '24px', breakpointSmall: '100px', breakpointLarge: '144px' }}
    >
      <div>{children}</div>
    </Spacing>
  </SideSliceContentContainer>
);

type TwoColumnContentImageProps = {
  heading: PrismicRichTextType;
  text: string;
  primaryCta: LinkType;
  secondaryCta: LinkType;
  desktopImage: ComponentProps<typeof PrismicImage>['image'];
  mobileImage: ComponentProps<typeof PrismicImage>['image'];
  backgroundType?: Background;
  contentSide: Side;
} & WithClassName;
/**
 * Renders TwoColumnContentImage slice.
 */
const TwoColumnContentImage: FC<TwoColumnContentImageProps> = ({
  className,
  heading,
  text,
  primaryCta,
  secondaryCta,
  desktopImage,
  mobileImage,
  contentSide,
  backgroundType = 'brand3GradientStripedCurve',
}) => {
  const noButtons = !primaryCta.link?.url && !primaryCta.text && !secondaryCta.link?.url && !secondaryCta.text;
  const isDarkBackground = backgrounds[backgroundType].isDark;
  const secondaryButtonStyle = isDarkBackground ? 'reverseSecondary' : 'secondary';

  return (
    <SliceContainer className={className} background={backgroundType}>
      <SideSliceContent contentSide={contentSide} desktopImage={desktopImage}>
        <Heading2 textAlign="left">{heading}</Heading2>
        <Spacing top="24px" bottom={{ base: '48px', breakpointMedium: 'none' }}>
          <BodyP type="bodyLRegular">{text}</BodyP>
        </Spacing>
        <MobileImage image={mobileImage} />
        {!noButtons && (
          <Spacing top={{ base: 'none', breakpointMedium: '24px' }}>
            <ButtonsPlacement
              cta1Placement={
                <ButtonLink styleType="primary" link={primaryCta.link}>
                  {primaryCta.text}
                </ButtonLink>
              }
              cta2Placement={
                <ButtonLink styleType={secondaryButtonStyle} link={secondaryCta.link}>
                  {secondaryCta.text}
                </ButtonLink>
              }
            />
          </Spacing>
        )}
      </SideSliceContent>
    </SliceContainer>
  );
};

export default TwoColumnContentImage;
