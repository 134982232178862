import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { BrandMarkWithWordMarkLeft } from '../../design/icons/brandMark/BrandMark';
import Spacing from '../../design/spacing/Spacing';
import Link from '../link/Link';
import { LinkType } from '../link/types';
import { BurgerButton } from './components/buttons/BurgerButton';
import { ContentContainer } from './components/containers/ContentContainer';
import { HeaderContainer } from './components/containers/HeaderContainer';
import { Ctas, CtasProps } from './components/ctaSection/Ctas';
import { Menu, MenuProps } from './components/menu/Menu';

type Props = { brandLogoLink: LinkType['link'] } & CtasProps & MenuProps;
const CenteredWrapper = styled.div`
  max-width: 2000px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * Renders the header.
 */
const Header: FC<Props> = ({ brandLogoLink, megaDropdownCta, primaryCta, secondaryCta1, secondaryCta2, items }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <HeaderContainer>
      <CenteredWrapper>
        <ContentContainer>
          <Spacing left={{ base: '12px' }}>
            <Link link={brandLogoLink} data-testid="logo-testid">
              <BrandMarkWithWordMarkLeft />
            </Link>
          </Spacing>
          <Spacing left={{ base: 'none', breakpointMedium: '12px' }} right={{ breakpointMedium: '12px' }}>
            <Menu items={items} open={menuOpen} megaDropdownCta={megaDropdownCta} />
          </Spacing>
        </ContentContainer>
        <ContentContainer>
          <Ctas primaryCta={primaryCta} secondaryCta1={secondaryCta1} secondaryCta2={secondaryCta2} open={menuOpen} />
          <BurgerButton data-testid="mobile-burger-button" onClick={toggleMenu} toggled={menuOpen} />
        </ContentContainer>
      </CenteredWrapper>
    </HeaderContainer>
  );
};

export default Header;
