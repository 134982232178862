import styled, { css } from 'styled-components';
import { brand2Mid } from '../../colors/brand';
import { grey300 } from '../../colors/greyscale';

const dividerStyles = {
  brand2MidOpacity0point3: css`
    border-top: 1px solid ${brand2Mid};
    opacity: 0.3;
  `,
  grey300: css`
    border-top: 1px solid ${grey300};
  `,
};

type DividerProps = { styleType: keyof typeof dividerStyles };
export const Divider = styled.div<DividerProps>`
  ${(props) => dividerStyles[props.styleType]}
`;
