import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import Heading2 from '../../design/typography/Heading2';
import { WithClassName } from '../../utlityTypes';
import FaqItem from './FaqItem';

const FaqItemsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpointSmall(css`
    flex-direction: row;
  `)}
`;

const FaqItemsColumn = styled.div`
  ${breakpointSmall(css`
    flex: 1;
  `)}
`;

const FaqItemsColumnLeft = styled(FaqItemsColumn)`
  ${breakpointSmall(css`
    margin-right: ${spacings['12px']};
  `)}
`;

const FaqItemsColumnRight = styled(FaqItemsColumn)`
  ${breakpointSmall(css`
    margin-left: ${spacings['12px']};
  `)}
`;

type FaqsProps = {
  heading: PrismicRichTextType;
  items?: Array<Queries.PrismicFaqsDefaultItem>;
} & WithClassName;

const getItemsBasedOnIndex = <T,>(items: T[], indexPredicate: (index: number) => boolean) =>
  items.reduce((acc, item, index) => (indexPredicate(index) ? [...acc, item] : acc), [] as typeof items);

const isOdd = (number: number) => number % 2 !== 0;
const isEven = (number: number) => number % 2 === 0;

/**
 * Renders FAQs slice.
 */
const Faqs: FC<FaqsProps> = ({ className, heading, items }) => {
  const dataFirstCol = items && getItemsBasedOnIndex(items, isOdd);
  const dataSecondCol = items && getItemsBasedOnIndex(items, isEven);

  return (
    <SliceContainer className={className} background="brand4LightSolidWeelLogo">
      <CenteredSliceContentContainer>
        <Heading2 textAlign="center">{heading}</Heading2>
        <Spacing top={{ base: '24px', breakpointSmall: '48px' }}>
          <FaqItemsContainer>
            <FaqItemsColumnLeft>
              {dataFirstCol?.map((item, key) => (
                <FaqItem key={key} index={key} heading={item.heading} text={item.text} />
              ))}
            </FaqItemsColumnLeft>
            <FaqItemsColumnRight>
              {dataSecondCol?.map((item, key) => (
                <FaqItem key={key} index={key} heading={item.heading} text={item.text} />
              ))}
            </FaqItemsColumnRight>
          </FaqItemsContainer>
        </Spacing>
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};

export default Faqs;
