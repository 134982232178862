import React, { FC } from 'react';
import styled from 'styled-components';
import { LinkType } from '../../components/link/types';
import { ButtonLink } from '../../design/components/button/Button';
import { Divider } from '../../design/components/divider/Divider';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { BodyP, Heading4 } from '../../design/typography/Typography';

const Body = styled(BodyP)`
  color: white;
  padding-top: ${spacings['8px']};
`;

const Button = styled(ButtonLink)`
  width: fit-content;
  margin-top: ${spacings['12px']};
`;

type ContentInfoItemProps = {
  heading?: string | null;
  text?: string | null;
  ctaText?: string | null;
  ctaLink: LinkType['link'] | null;
  lastContent: boolean;
};

/**
 * Renders ContentInfoBlock item.
 */
const ContentInfoItem: FC<ContentInfoItemProps> = ({ heading, text, ctaText, ctaLink, lastContent }) => {
  return (
    <>
      <Spacing bottom="24px" top="24px">
        <div>
          <Heading4 textAlign="left">{heading}</Heading4>
          <Body type="bodySRegular">{text}</Body>
          <Button size="medium" styleType="primary" link={ctaLink}>
            {ctaText}
          </Button>
        </div>
      </Spacing>
      {!lastContent && <Divider styleType="brand2MidOpacity0point3" />}
    </>
  );
};

export default ContentInfoItem;
