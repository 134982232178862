import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Quote from '../Quote';

type QuoteDataProps = SliceProps<'quote', SliceVariation<'default', Queries.PrismicQuoteDefaultPrimary>> &
  WithClassName;
/**
 * Quote data component: specifies the fragment and passes the appropriate data to the Quote component.
 */
const QuoteData: FC<QuoteDataProps> = ({ className, slice }) => (
  <Quote
    className={className}
    quoteText={slice.primary?.quoteText}
    quoteAuthor={slice.primary?.quoteAuthor || ''}
    primaryCta={{ text: slice.primary?.primaryCtaButtonText || '', link: slice.primary?.primaryCtaLink }}
    secondaryCta={{ text: slice.primary?.secondaryCtaButtonText || '', link: slice.primary?.secondaryCtaLink }}
    image={slice.primary?.image}
  />
);

export default QuoteData;

/**
 * @todo Convert quoteText to rich text heading.
 */
export const query = graphql`
  fragment Quote on PrismicQuoteDefault {
    primary {
      quoteText {
        ...RichText
      }
      quoteAuthor
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      image {
        ...Image
      }
    }
  }
`;
