import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import SocialProof from '../SocialProof';

type SocialProofDataProps = SliceProps<
  'socialProof',
  SliceVariation<'default', Queries.PrismicSocialProofDefault> | SliceVariation<'grey', Queries.PrismicSocialProofGrey>
> &
  WithClassName;
/**
 * SocialProof data component: specifies the fragment and passes the appropriate data to the SocialProof component.
 */
const SocialProofData: FC<SocialProofDataProps> = ({ className, slice }) => (
  <SocialProof className={className} background={slice.variation === 'default' ? 'whiteSolid' : 'grey200Solid'} />
);

export default SocialProofData;

export const query = graphql`
  fragment SocialProofDefault on PrismicSocialProofDefault {
    variation
  }

  fragment SocialProofGrey on PrismicSocialProofGrey {
    variation
  }

  fragment SocialProof on PrismicSliceType {
    ...SocialProofDefault
    ...SocialProofGrey
  }
`;
