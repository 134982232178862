import styled from 'styled-components';
import { WithTestId } from '../../../utlityTypes';
import { Panel } from '../panel/Panel';
import { sliceContainerTestId } from './SliceContainer.page';

/**
 * Container for all slices. Renders the rounded panel and allows selection of background.
 */
export const SliceContainer = styled(Panel)<WithTestId>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
SliceContainer.defaultProps = {
  'data-testid': sliceContainerTestId,
  padding: { left: '24px', right: '24px' },
};
