import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import CompanyQuote from '../CompanyQuote';

type CompanyQuoteProps = SliceProps<
  'companyQuote',
  SliceVariation<'default', Queries.PrismicCompanyQuoteDefaultPrimary>
> &
  WithClassName;

/**
 * Company quote data component: specifies the fragment and passes the appropriate data to the CompanyQuote component.
 */
const CompanyQuoteData: FC<CompanyQuoteProps> = ({ className, slice }) => (
  <CompanyQuote
    className={className}
    heading={slice.primary.heading}
    subHeading={slice.primary?.subHeading || ''}
    author={{
      name: slice.primary?.authorName || '',
      position: slice.primary?.authorPosition || '',
      company: slice.primary?.authorCompany || '',
    }}
    quote={slice.primary?.quote || ''}
    company={slice.primary?.company || ''}
  />
);

export default CompanyQuoteData;

export const query = graphql`
  fragment CompanyQuote on PrismicCompanyQuoteDefault {
    primary {
      heading {
        ...RichText
      }
      subHeading
      authorName
      authorPosition
      authorCompany
      quote
      company
    }
  }
`;
