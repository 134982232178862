import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import TwoColumnFourCards from '../TwoColumnFourCards';

type TwoColumnFourCardsDataProps = SliceProps<
  'twoColumnFourCards',
  SliceVariation<
    'default',
    Queries.PrismicTwoColumnFourCardsDefaultPrimary,
    Queries.PrismicTwoColumnFourCardsDefaultItem
  >
> &
  WithClassName;
/**
 * TwoColumnFourCards data component: specifies the fragment and passes the appropriate data to the TwoColumnFourCards component.
 */
const TwoColumnFourCardsData: FC<TwoColumnFourCardsDataProps> = ({ className, slice }) => {
  return (
    <TwoColumnFourCards
      className={className}
      heading={slice.primary.heading}
      items={slice.items || []}
      primaryCta={{ text: slice.primary.primaryCtaButtonText || '', link: slice.primary.primaryCtaLink }}
      secondaryCta={{ text: slice.primary.secondaryCtaButtonText || '', link: slice.primary.secondaryCtaLink }}
    />
  );
};

export default TwoColumnFourCardsData;

export const query = graphql`
  fragment TwoColumnFourCards on PrismicTwoColumnFourCardsDefault {
    primary {
      heading {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
    items {
      feature
      heading
      text
      buttonText
      buttonLink {
        ...PrismicLink
      }
    }
  }
`;
