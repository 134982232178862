import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { LinkType } from '../../../components/link/types';
import { OnVisible } from '../../../design/components/onVisible/OnVisible';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointSmall } from '../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../design/spacing/Spacing';
import { BodyP, Heading4 } from '../../../design/typography/Typography';
import { WithClassName } from '../../../utlityTypes';
import Price from '../columns/Price';
import { HalfWidthButton } from '../columns/PricingColumns';
import List from '../list/List';

const Container = styled(Panel).attrs({ background: 'brand2LogoRight', borderRadius: '12px' })`
  width: 100%;
  display: flex;
  gap: ${spacings['48px']};
  padding: ${spacings['32px']};
  flex-direction: column;
  height: 735px;

  ${breakpointSmall(css`
    gap: ${spacings['100px']};
    padding: ${spacings['48px']};
    flex-direction: row;
    height: 100%;
  `)};
`;

export type BottomPanelListItems = {
  firstItem?: string;
  secondItem?: string;
  thirdItem?: string;
  fourthItem?: string;
  fifthItem?: string;
};
export type BottomCardProps = {
  heading?: string;
  subHeading?: string;
  price?: string;
  pricingPeriod?: string;
  ctaButton?: LinkType;
  listItems: BottomPanelListItems;
} & WithClassName;

const LeftSection: FC<Omit<BottomCardProps, 'listItems'>> = ({
  subHeading,
  heading,
  price,
  pricingPeriod,
  ctaButton,
}) => (
  <div>
    <BodyP textAlign="left" type="bodyLRegular">
      {subHeading}
    </BodyP>
    <Heading4 textAlign="left">{heading}</Heading4>
    <Price price={price || ''} pricingPeriod={pricingPeriod || ''} />
    <HalfWidthButton size="medium" styleType="primary" link={ctaButton?.link || null}>
      {ctaButton?.text}
    </HalfWidthButton>
  </div>
);

type Props = { bottomCard: BottomCardProps };
/**
 * Renders the bottom card for Pricing slice.
 */
const PricingBottomCard: FC<Props> = ({ bottomCard }) => {
  const { heading, subHeading, price, pricingPeriod, ctaButton, listItems } = bottomCard;
  const items = Object.values(listItems);
  return (
    <OnVisible fadeUp style={{ width: '100%' }}>
      <Spacing bottom="48px">
        <Container>
          <LeftSection
            heading={heading}
            subHeading={subHeading}
            price={price}
            pricingPeriod={pricingPeriod}
            ctaButton={ctaButton}
          />
          <List items={items} />
        </Container>
      </Spacing>
    </OnVisible>
  );
};

export default PricingBottomCard;
