import React, { FC } from 'react';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import Spacing from '../../design/spacing/Spacing';
import Heading1 from '../../design/typography/Heading1';
import { BodySpan } from '../../design/typography/Typography';
import PricingBottomCard, { BottomCardProps } from './bottomCard/PricingBottomCard';
import PricingColumns, { ItemPricingProps } from './columns/PricingColumns';

type PricingProps = {
  heading: PrismicRichTextType;
  text?: string;
  terms?: string;
  bottomCard: BottomCardProps;
} & ItemPricingProps;

const Heading: FC<Pick<PricingProps, 'heading' | 'text'>> = ({ heading, text }) => (
  <CenteredSliceContentContainer verticalPadding="100px" maxWidth="814px">
    <Heading1 textAlign="center">{heading}</Heading1>
    {text && (
      <Spacing top={{ base: '24px' }}>
        <BodySpan type="bodyLRegular" textAlign="center">
          {text}
        </BodySpan>
      </Spacing>
    )}
  </CenteredSliceContentContainer>
);

const TermsAndConditions: FC<Pick<PricingProps, 'terms'>> = ({ terms }) => (
  <>
    {terms && (
      <Spacing bottom={{ base: '64px' }}>
        <BodySpan type="bodyLRegular" textAlign="center">
          {terms}
        </BodySpan>
      </Spacing>
    )}
  </>
);

/**
 * Renders Pricing slice.
 */
const Pricing: FC<PricingProps> = ({ className, heading, text, items, terms, bottomCard }) => {
  return (
    <SliceContainer className={className} background="brand5ExpressiveWaveLime">
      <CenteredSliceContentContainer verticalPadding="none" maxWidth="1286px" className={className}>
        <Heading heading={heading} text={text} />
        <PricingColumns items={items} />
        <TermsAndConditions terms={terms} />
        <PricingBottomCard bottomCard={bottomCard} />
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};

export default Pricing;
