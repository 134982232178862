import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { RawImage } from '../../components/image/rawImage/RawImage';
import { grow } from '../../design/animations/micro-interactions';
import { grey700 } from '../../design/colors/greyscale';
import { Divider } from '../../design/components/divider/Divider';
import { Background } from '../../design/containers/panel/Panel';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall, breakpointXSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { Heading4 } from '../../design/typography/Typography';
import c3 from '../../images/icons/C3.svg';
import canva from '../../images/icons/Canva.svg';
import michaelHill from '../../images/icons/Michael hill.svg';
import unitingAgeWell from '../../images/icons/Uniting age well.svg';
import weAreSocial from '../../images/icons/We are social.svg';
import xero from '../../images/icons/Xero.svg';
import { WithClassName } from '../../utlityTypes';

type SocialProofProps = {
  background: Background;
} & WithClassName;

const MobileDividerElement = styled(Divider).attrs({ styleType: 'grey300' })`
  min-width: 100%;
  flex: 1;
  ${breakpointXSmall(css`
    display: none;
  `)}
`;

const Title = styled(Heading4)`
  ${breakpointSmall(css`
    max-width: 328px;
  `)}
`;

const MobileDivider = () => (
  <Spacing top={{ base: '24px', breakpointSmall: 'none' }} bottom={{ base: '24px', breakpointSmall: 'none' }}>
    <MobileDividerElement />
  </Spacing>
);

const WrapContainer = styled.div`
  gap: ${spacings['24px']};
  color: ${grey700};
  max-width: 1000px;
  width: 100%;
  margin: 48px auto;
  display: flex;
  flex-direction: column;
  gap: ${spacings['24px']};
  ${breakpointSmall(css`
    display: grid;
    margin: 24px auto;
    grid-template-columns: 1fr 2fr;
    gap: ${spacings['24px']};
  `)}
`;

const WrapIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${spacings['24px']};
`;

const WrapIcon = styled(RawImage)`
  && {
    ${grow}
  }
  max-height: ${spacings['48px']};
`;

const heading = "Trusted by Australia's fastest growing businesses";
const icons = [
  { name: 'Canva', icon: canva },
  { name: 'Michael Hill', icon: michaelHill },
  { name: 'C3', icon: c3 },
  { name: 'We are Social', icon: weAreSocial },
  { name: 'Uniting AgeWell', icon: unitingAgeWell },
  { name: 'Xero', icon: xero },
];

/**
 * Renders SocialProof slice.
 */
const SocialProof: FC<SocialProofProps> = ({ className, background }) => {
  return (
    <SliceContainer className={className} background={background} data-testid="social-proof">
      <WrapContainer>
        <Title textAlign="center">{heading}</Title>
        <MobileDivider />
        <WrapIcons>
          {icons.map((item, index) => {
            const { name, icon } = item;
            return (
              <>
                <WrapIcon key={name} src={icon} alt={name} />
                {index === 2 && <MobileDivider />}
              </>
            );
          })}
        </WrapIcons>
      </WrapContainer>
    </SliceContainer>
  );
};

export default SocialProof;
