import React, { FC, useContext } from 'react';
import styled, { css } from 'styled-components';
import { toggleShow } from '../../../../design/animations/display';
import { standardTransition } from '../../../../design/animations/transitions';
import { brand5 } from '../../../../design/colors/brand';
import { grey300 } from '../../../../design/colors/greyscale';
import { baseButtonStyles } from '../../../../design/components/button/Button';
import { tertiaryTypeStyle } from '../../../../design/components/button/styles/buttonType/brand/tertiary/tertiary';
import { breakpointMedium } from '../../../../design/responsive/breakpoints';
import { spacings } from '../../../../design/spacing/Spacing';
import { textStyles } from '../../../../design/typography/base/Text';
import ChevronRight from '../../../../images/react-icons/ChevronRight.inline.svg';
import { HeaderContext } from '../../../../pageContainers/HeaderContext';
import { WithTestId } from '../../../../utlityTypes';
import Link from '../../../link/Link';
import { LinkType } from '../../../link/types';
import { HeaderItem, HeaderLink, HeaderMegaDropdown } from '../../types';
import { HiddenOnDesktop } from '../../utils/Hidden';
import { navButtonStyles } from '../buttons/NavButton';
import { Dropdown, DropdownContainer } from './dropdown/Dropdown';

const topLevelNavButtonMobileStyles = () => css`
  ${baseButtonStyles}
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-color: ${grey300};
  border-style: none;
  border-bottom-style: solid;
  padding: ${spacings['16px']} ${spacings['24px']};
  ${textStyles({ fontWeight: '400', fontSize: '22pt', letterSpacing: '0', lineHeight: '140%' })}
  &:hover {
    background: ${brand5};
  }
`;

const topLevelNavButtonStyles = css`
  ${topLevelNavButtonMobileStyles()}
  ${breakpointMedium(css`
    ${navButtonStyles()}
  `)}
`;

const TopLevelNavLink = styled(Link)`
  ${topLevelNavButtonStyles}
`;

export const topLevelNavButtonActiveStyles = css`
  background-color: ${brand5};
  ${breakpointMedium(css`
    ${tertiaryTypeStyle()}
  `)}
`;

type TopLevelNavButtonProps = { active?: boolean };
export const TopLevelNavButton = styled.button<TopLevelNavButtonProps>`
  ${topLevelNavButtonStyles}
  ${(props) => props.active && topLevelNavButtonActiveStyles}
`;

type ChevronProps = { rotatedDown?: boolean } & WithTestId;
const Chevron = styled(ChevronRight)<ChevronProps>`
  transition: transform ${standardTransition};
  transform: ${(props) => (props.rotatedDown ? 'rotate(90deg)' : 'rotate(0deg)')};
`;
Chevron.defaultProps = { 'data-testid': 'chevron' };

type NavLinkProps = { item: HeaderLink };
const NavLink: FC<NavLinkProps> = ({ item }) => <TopLevelNavLink link={item.link}>{item.heading}</TopLevelNavLink>;

const DropdownNavItemContainer = styled.div<TopLevelNavButtonProps>`
  display: flex;
  flex-direction: column-reverse;
  ${breakpointMedium(css`
    display: block;
    position: relative;
    padding-bottom: 2.5em;
    margin-bottom: -2.5em;
    &:hover {
      ${DropdownContainer} {
        ${toggleShow(true)}
        top: ${spacings['64px']};
        width: max-content;
        &:hover {
          & + button {
            ${topLevelNavButtonActiveStyles}
          }
        }
      }
    }
  `)}
`;

type DropdownNavItemProps = { item: HeaderMegaDropdown; megaDropdownCta: LinkType };
const DropdownNavItem: FC<DropdownNavItemProps> = ({ item, megaDropdownCta }) => {
  const { dropdownsStatus, changeDropdownStatus } = useContext(HeaderContext);

  const open = dropdownsStatus[item.heading];

  const toggleMenu = () => {
    changeDropdownStatus(item.heading, !open);
  };

  return (
    <DropdownNavItemContainer>
      <Dropdown
        leftSection={item.leftSection}
        rightSection={item.rightSection}
        megaDropdownCta={megaDropdownCta}
        hide={!open}
      />
      <TopLevelNavButton onClick={toggleMenu} active={open}>
        {item.heading}
        <HiddenOnDesktop>
          <Chevron rotatedDown={open} />
        </HiddenOnDesktop>
      </TopLevelNavButton>
    </DropdownNavItemContainer>
  );
};

type NavItemProps = { item: HeaderItem; megaDropdownCta: LinkType };
/**
 * Renders a nav item.
 */
export const NavItem: FC<NavItemProps> = ({ item, megaDropdownCta }) =>
  'link' in item ? <NavLink item={item} /> : <DropdownNavItem item={item} megaDropdownCta={megaDropdownCta} />;
