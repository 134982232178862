import { SliceZone } from '@prismicio/react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { FC } from 'react';
import PageContainer from '../../pageContainers/PageContainer';
import ImageData from '../../slices/image/data/Image.queryData';
import withBottomSpacing from '../../slices/utils/withBottomSpacing';
import WysiwygData from '../../slices/wysiwyg/data/Wysiwyg.queryData';
import BlogHeader from './BlogHeader/BlogHeader';
import ContentWrapper from './ContentWrapper/ContentWrapper';

const components = {
  wysiwyg: withBottomSpacing(WysiwygData),
  image: withBottomSpacing(ImageData),
};

/**
 * Renders Prismic BlogPost document and maps slice data to be rendered by their components in order.
 */
const BlogPost: FC<PageProps<Queries.BlogPostQuery>> = ({ data }) => {
  const headerId = data.prismicBlogPost?.data.header?.id || undefined;
  const footerId = data.prismicBlogPost?.data.footer?.id || undefined;
  const postData = data?.prismicBlogPost?.data;
  const postAuthorDocument = data.prismicBlogPost?.data.author?.document;
  const postAuthor = postAuthorDocument && 'data' in postAuthorDocument ? postAuthorDocument.data : undefined;
  const slices = postData?.slices;

  const seo = {
    title: postData?.Title || '',
    description: postData?.Description || '',
    ogImage: postData?.Image?.url,
    // schema: @todo add later
  } as const;

  return (
    <PageContainer headerId={headerId} footerId={footerId} seoProps={seo}>
      <BlogHeader title={postData?.title} date={postData?.date || ''} author={postAuthor?.name || ''} />
      <ContentWrapper>
        <SliceZone slices={slices} components={components} />
      </ContentWrapper>
    </PageContainer>
  );
};

export default withPrismicPreview(BlogPost);

export const query = graphql`
  fragment AuthorDocument on PrismicAuthor {
    data {
      name
      title
    }
  }

  query BlogPost($url: String!) {
    prismicBlogPost(url: { eq: $url }) {
      _previewable
      data {
        ...BlogSEO
        ...BlogPostHeader
        ...BlogPostFooter
        author {
          document {
            ...AuthorDocument
          }
        }
        date(formatString: "MMMM D, YYYY")
        title {
          ...RichText
        }
        slices {
          ...Slice
          ...Wysiwyg
          ...ImageSlice
        }
      }
    }
  }
`;
