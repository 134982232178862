import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import BlogPostCards from '../BlogPostCards';

type BlogPostCardsDataProps = SliceProps<'blog_post_cards', SliceVariation<'default'>> & WithClassName;

/**
 * BlogPostCards data component: specifies the fragment and passes the appropriate data to the BlogPostCards component.
 */
const BlogPostCardsData: FC<BlogPostCardsDataProps> = ({ className }) => {
  const postsData: Queries.AllPostsQuery = useStaticQuery(graphql`
    query AllPosts {
      allPrismicBlogPost {
        nodes {
          url
          data {
            excerpt
            author {
              document {
                ... on PrismicAuthor {
                  data {
                    name
                    title
                  }
                }
              }
            }
            title {
              text
            }
            date(formatString: "MMMM D, YYYY")
          }
        }
      }
    }
  `);

  const posts = postsData?.allPrismicBlogPost?.nodes || [];

  return (
    <BlogPostCards
      posts={posts.map((post) => {
        const maybeAuthorDocument = post.data.author?.document;
        const authorDocument = maybeAuthorDocument && 'data' in maybeAuthorDocument ? maybeAuthorDocument : undefined;
        return {
          url: post.url || '',
          date: post.data.date || '',
          author: authorDocument?.data.name || '',
          title: post.data.title?.text || '',
          excerpt: post.data.excerpt || '',
        };
      })}
      className={className}
    />
  );
};

export default BlogPostCardsData;
