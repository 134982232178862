import React, { ButtonHTMLAttributes, ComponentProps, DetailedHTMLProps, FC, PropsWithChildren } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import Link from '../../../components/link/Link';
import generateResponsiveStyles, { ResponsiveValue } from '../../responsive/generateResponsiveStyles';
import { fontFamilyStyles } from '../../typography/base/Text';
import { errorTypeStyle } from './styles/buttonType/alert/error/error';
import { successTypeStyle } from './styles/buttonType/alert/success/success';
import { warningTypeStyle } from './styles/buttonType/alert/warning/warning';
import { primaryTypeStyle } from './styles/buttonType/brand/primary/primary';
import { primaryReverseTypeStyle } from './styles/buttonType/brand/primary/primaryReverse';
import { secondaryTypeStyle } from './styles/buttonType/brand/secondary/secondary';
import { secondaryReverseTypeStyle } from './styles/buttonType/brand/secondary/secondaryReverse';
import { tertiaryTypeStyle } from './styles/buttonType/brand/tertiary/tertiary';
import { tertiaryReverseTypeStyle } from './styles/buttonType/brand/tertiary/tertiaryReverse';
import { transparentTypeStyle } from './styles/buttonType/brand/transparent/transparent';
import { largeStyles } from './styles/size/large/large';
import { mediumStyles } from './styles/size/medium/medium';
import { smallStyles } from './styles/size/small/small';
import { xLargeStyles } from './styles/size/xLarge/xLarge';

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'error'
  | 'warning'
  | 'success'
  | 'reversePrimary'
  | 'reverseSecondary'
  | 'reverseTertiary'
  | 'transparent';
type ResponsiveButtonType = ResponsiveValue<ButtonType>;
type ButtonSize = 'small' | 'medium' | 'large' | 'x-large';
type ResponsiveButtonSize = ResponsiveValue<ButtonSize>;

const getTypeStyle = (type: ResponsiveButtonType) => {
  const styleTypeMap: Record<ButtonType, FlattenSimpleInterpolation> = {
    primary: primaryTypeStyle(),
    secondary: secondaryTypeStyle(),
    tertiary: tertiaryTypeStyle(),
    error: errorTypeStyle(),
    warning: warningTypeStyle(),
    success: successTypeStyle(),
    reversePrimary: primaryReverseTypeStyle(),
    reverseSecondary: secondaryReverseTypeStyle(),
    reverseTertiary: tertiaryReverseTypeStyle(),
    transparent: transparentTypeStyle(),
  };

  return generateResponsiveStyles(type, (value) => styleTypeMap[value]);
};

const getSizeStyle = (size: ResponsiveButtonSize) => {
  const sizeStyleMap: Record<ButtonSize, FlattenSimpleInterpolation> = {
    small: smallStyles(),
    medium: mediumStyles(),
    large: largeStyles(),
    'x-large': xLargeStyles(),
  };

  const sizeValue = typeof size === 'string' ? ({ base: 'x-large', breakpointSmall: size } as const) : size;

  return generateResponsiveStyles(sizeValue, (size) => sizeStyleMap[size]);
};

export const baseButtonStyles = css`
  ${fontFamilyStyles}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
  }

  &:disabled:hover {
    cursor: not-allowed;
  }
`;

export type ButtonProps = {
  styleType?: ResponsiveButtonType;
  size?: ResponsiveButtonSize;
  disabled?: boolean;
  active?: boolean;
};
const buttonStyles = css<ButtonProps>`
  ${baseButtonStyles}
  ${(props) => css`
    ${getTypeStyle(props.styleType || 'primary')}
    ${getSizeStyle(props.size || 'large')}
  `}
`;

const StyledButton = styled.button`
  ${buttonStyles}
`;

const StyledButtonLink = styled(Link)`
  ${buttonStyles}
`;

type AllButtonElementProps = Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'ref'>;
type ButtonElementProps = Pick<AllButtonElementProps, 'onClick'>;
/**
 * Use for all Buttons that are not links, but trigger some JavaScript action or form submission.
 * This renders a `<button>` element.
 *
 * For links that look like buttons, use ButtonLink.
 */
export const Button: FC<PropsWithChildren<ButtonProps & ButtonElementProps>> = (props) => <StyledButton {...props} />;

/**
 * Use for all CTAs. This renders an `<a>` that looks like a button.
 */
export const ButtonLink: FC<PropsWithChildren<ButtonProps & ComponentProps<typeof Link>>> = (props) => (
  <StyledButtonLink {...props} />
);
