import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { WithClassName } from '../../../utlityTypes';
import generateResponsiveStyles, { ResponsiveValue } from '../../responsive/generateResponsiveStyles';
import { stripInvalidCharacters } from './utils';

export type FontWeight = '400' | '500' | '600';
/**
 * Get font weight.
 */
export const getFontWeight = (value: FontWeight) => {
  const fontWeights = {
    '400': 400,
    '500': 500,
    '600': 600,
  } as const;
  return fontWeights[value];
};

export type FontSize =
  | '100pt'
  | '64pt'
  | '48pt'
  | '40pt'
  | '32pt'
  | '24pt'
  | '22pt'
  | '18pt'
  | '16pt'
  | '14pt'
  | '12pt';
/**
 * Get font size as number of pixels.
 */
export const getFontSizeNumber = (value: FontSize) => {
  const fontSizes = {
    '100pt': 100,
    '64pt': 64,
    '48pt': 48,
    '40pt': 40,
    '32pt': 32,
    '24pt': 24,
    '22pt': 22,
    '18pt': 18,
    '16pt': 16,
    '14pt': 14,
    '12pt': 12,
  } as const;
  return fontSizes[value];
};

/**
 * Get font size as pixel string value.
 */
export const getFontSize = (value: FontSize) => `${getFontSizeNumber(value)}px`;

type LineHeight = '100%' | '110%' | '120%' | '140%' | '150%';
/**
 * Get line height.
 */
export const getLineHeight = (value: LineHeight) => {
  const lineHeights = {
    '100%': '100%',
    '110%': '110%',
    '120%': '120%',
    '130%': '130%',
    '140%': '140%',
    '150%': '150%',
  } as const;
  return lineHeights[value];
};

type LetterSpacing = '0' | '-1%' | '-4%';
/**
 * Get letter spacing.
 */
export const getLetterSpacing = (value: LetterSpacing) => {
  const letterSpacings = {
    '0': '0',
    '-1%': '-0.01em',
    '-4%': '-0.04em',
  } as const;
  return letterSpacings[value];
};

type TextAlign = 'left' | 'right' | 'center';

type TextOptions = {
  fontWeight?: FontWeight;
  fontSize?: FontSize;
  textAlign?: ResponsiveValue<TextAlign>;
  lineHeight?: LineHeight;
  letterSpacing?: LetterSpacing;
};

export const fontFamilyStyles = css`
  font-family: Aeonik, Helvetica, sans-serif;
`;

/**
 * Generates text style from the set of allowed properties.
 */
export const textStyles = ({ fontSize, fontWeight, textAlign, lineHeight, letterSpacing }: TextOptions = {}) => css`
  ${fontSize && `font-size: ${getFontSize(fontSize)};`}
  ${fontWeight && `font-weight: ${getFontWeight(fontWeight)};`}
  ${textAlign && generateResponsiveStyles(textAlign, (value) => `text-align: ${value};`)}
  ${lineHeight && `line-height: ${getLineHeight(lineHeight)};`}
  ${letterSpacing && `letter-spacing: ${getLetterSpacing(letterSpacing)};`}
  -webkit-font-smoothing: antialiased;
`;

export type TextProps = Pick<TextOptions, 'textAlign'>;
const TextElement = styled.div<TextProps>`
  ${fontFamilyStyles}
  ${textStyles}
`;

const stripChildrenIfString = (children: any) =>
  typeof children === 'string' ? stripInvalidCharacters(children) : children;

const sanitiseTextChildren = (children: any) =>
  children.map ? children.map((child: any) => stripChildrenIfString(child)) : stripChildrenIfString(children);

type Props = { element?: any } & TextProps & WithClassName;
/**
 * Base text component that provides common functionality for all typography. Should *not* be directly used.
 */
const Text: FC<PropsWithChildren<Props>> = ({ children, element, ...textProps }) => {
  const sanitisedChildren = children && sanitiseTextChildren(children);
  return (
    <TextElement as={element} {...textProps}>
      {sanitisedChildren}
    </TextElement>
  );
};

export default Text;
