import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { WithClassName } from '../../utlityTypes';

const sixteenByNineHeightRatio = 56.25;

export type AspectRatioContainerProps = {
  /**
   * The ratio of the height compared to the width as a percentage.
   *
   * Eg. For 16:9, the heightRatio would be 9/16 = 56.25.
   */
  heightRatio?: number;
};
const AspectRatioInnerContainer = styled.div<AspectRatioContainerProps>`
  position: relative;
  height: 0;
  width: 100%;
  padding-top: ${(props) => props.heightRatio || sixteenByNineHeightRatio}%;
`;

type Props = AspectRatioContainerProps & WithClassName;
/**
 * Container that is always at the specified aspect ratio.
 */
export const AspectRatioContainer: FC<PropsWithChildren<Props>> = ({ className, heightRatio, children }) => (
  <div className={className}>
    <AspectRatioInnerContainer heightRatio={heightRatio}>{children}</AspectRatioInnerContainer>
  </div>
);
