import React, { FC } from 'react';
import styled from 'styled-components';
import { generateStylesForHeaderHeightAtBreakpoints } from '../../components/header/components/containers/HeaderContainer';
import { LinkType } from '../../components/link/types';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { ButtonLink } from '../../design/components/button/Button';
import { Background, isDarkBackground } from '../../design/containers/panel/Panel';
import TwoCtaPlacementContainer from '../../design/containers/placements/TwoCtaPlacementContainer';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import Spacing from '../../design/spacing/Spacing';
import Heading1 from '../../design/typography/Heading1';
import { BodyP } from '../../design/typography/Typography';
import { WithClassName } from '../../utlityTypes';

const HeroSliceContainer = styled(SliceContainer)`
  ${generateStylesForHeaderHeightAtBreakpoints((headerHeight) => `min-height: calc(50vh - ${headerHeight});`)}
`;

const TextContent = styled(BodyP).attrs({ type: 'bodyLRegular', textAlign: 'center' })`
  max-width: 564px;
`;

type HeroProps = {
  heading: PrismicRichTextType;
  text?: string;
  primaryCta: LinkType;
  secondaryCta: LinkType;
  backgroundType?: Background;
} & WithClassName;
/**
 * Renders Hero slice.
 */
const Hero: FC<HeroProps> = ({
  className,
  heading,
  text,
  primaryCta,
  secondaryCta,
  backgroundType = 'brand5GlassSpinnerLime',
}) => {
  const secondaryButtonStyle = isDarkBackground(backgroundType) ? 'reverseSecondary' : 'secondary';
  return (
    <HeroSliceContainer className={className} background={backgroundType}>
      <CenteredSliceContentContainer verticalPadding="100px" maxWidth="814px">
        <Heading1>{heading}</Heading1>
        <Spacing top="24px">
          <TextContent>{text}</TextContent>
        </Spacing>
        <Spacing top="24px" bottom={{ base: '144px', breakpointSmall: 'none' }}>
          <TwoCtaPlacementContainer
            cta1Placement={
              <ButtonLink styleType="primary" link={primaryCta.link}>
                {primaryCta.text}
              </ButtonLink>
            }
            cta2Placement={
              <ButtonLink styleType={secondaryButtonStyle} link={secondaryCta.link}>
                {secondaryCta.text}
              </ButtonLink>
            }
          />
        </Spacing>
      </CenteredSliceContentContainer>
    </HeroSliceContainer>
  );
};

export default Hero;
