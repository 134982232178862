import { LinkResolverFunction } from '@prismicio/helpers';

const replaceDotsWithSlashes = (input: string) => input.replace(/\./g, '/');
const addStartingSlash = (url: string) => (url.startsWith('/') ? url : `/${url}`);

const generatePathFromUid = (uid: string) => addStartingSlash(replaceDotsWithSlashes(uid));

/**
 * Gatsby link resolver that allows us to customize the url for each document.
 *
 * Docs can be found: https://prismic.io/docs/technologies/link-resolver-gatsby.
 */
const linkResolver: LinkResolverFunction = (doc) => {
  if (!doc.uid) return '/';
  const url = generatePathFromUid(doc.uid);
  if (doc.type === 'blog_post') return `/resources/the-weelhouse${url}`;
  return url;
};

export default linkResolver;
