import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import HubspotForm, { HubspotFormData, HubspotProps } from '../hubspotForm/HubspotForm';

const convertFormDataToLead = (formData: HubspotFormData) =>
  formData.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {});

/**
 * Renders Chili piper form for demo slice.
 */
const ChiliPiperForm: FC<HubspotProps> = ({ id, link, trackingEvent }) => {
  const chiliPiperSubmit = useCallback((formData: HubspotFormData) => {
    const cpTenantDomain = 'divipay';
    const cpRouterName = 'inbound_router';

    window.ChiliPiper.submit(cpTenantDomain, cpRouterName, { map: true, lead: convertFormDataToLead(formData) });
  }, []);
  return (
    <div>
      <Helmet>
        <script src="https://js.chilipiper.com/marketing.js" type="text/javascript"></script>
      </Helmet>
      <HubspotForm id={id} link={link} trackingEvent={trackingEvent} onSubmitCompleted={chiliPiperSubmit} />
    </div>
  );
};

export default ChiliPiperForm;
