import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Background } from '../../../design/containers/panel/Panel';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Hero from '../Hero';
import HomepageHero from '../HomepageHero';

const getBackgroundForVariation = (variation: HeroDataProps['slice']['variation']) => {
  const variationBackgroundMap: Record<typeof variation, Background> = {
    brand5GlassSpinnerLime: 'brand5GlassSpinnerLime',
    brand2GlassSpinnerTeal: 'brand2GlassSpinnerTeal',
    brand5WaveLime: 'brand5WaveLime',
    brand3FlexSpinnerAzul: 'brand3FlexSpinnerAzul',
    brand5CardTwistTeal: 'brand5CardTwistTeal',
    brand2WaveTeal: 'brand2WaveTeal',
    brand4WaveMidnight: 'brand4WaveMidnight',
    brand3WeelLogoAzul: 'brand3WeelLogoAzul',
    brand5FlexSpinnerBase: 'brand5FlexSpinnerBase',
    brand5WeelLogoBase: 'brand5WeelLogoBase',
    brand5FlexSpinnerLime: 'brand5FlexSpinnerLime',
    brand5GlassSpinnerTeal: 'brand5GlassSpinnerTeal',
    brand4CardStackMidnight: 'brand4CardStackMidnight',
    brand3CardStackAzul: 'brand3CardStackAzul',
    brand3HomepageHero: 'brand3Gradient',
  };
  return variationBackgroundMap[variation];
};

type HeroDataProps = SliceProps<
  'hero',
  | SliceVariation<'brand5GlassSpinnerLime', Queries.PrismicHeroBrand5GlassSpinnerLimePrimary>
  | SliceVariation<'brand2GlassSpinnerTeal', Queries.PrismicHeroBrand2GlassSpinnerTealPrimary>
  | SliceVariation<'brand5WaveLime', Queries.PrismicHeroBrand5WaveLimePrimary>
  | SliceVariation<'brand3FlexSpinnerAzul', Queries.PrismicHeroBrand3FlexSpinnerAzulPrimary>
  | SliceVariation<'brand5CardTwistTeal', Queries.PrismicHeroBrand5CardTwistTealPrimary>
  | SliceVariation<'brand2WaveTeal', Queries.PrismicHeroBrand2WaveTealPrimary>
  | SliceVariation<'brand4WaveMidnight', Queries.PrismicHeroBrand4WaveMidnightPrimary>
  | SliceVariation<'brand3WeelLogoAzul', Queries.PrismicHeroBrand3WeelLogoAzulPrimary>
  | SliceVariation<'brand5FlexSpinnerBase', Queries.PrismicHeroBrand5FlexSpinnerBasePrimary>
  | SliceVariation<'brand5WeelLogoBase', Queries.PrismicHeroBrand5WeelLogoBasePrimary>
  | SliceVariation<'brand5FlexSpinnerLime', Queries.PrismicHeroBrand5FlexSpinnerLimePrimary>
  | SliceVariation<'brand5GlassSpinnerTeal', Queries.PrismicHeroBrand5GlassSpinnerTealPrimary>
  | SliceVariation<'brand4CardStackMidnight', Queries.PrismicHeroBrand4CardStackMidnightPrimary>
  | SliceVariation<'brand3CardStackAzul', Queries.PrismicHeroBrand3CardStackAzulPrimary>
  | SliceVariation<'brand3HomepageHero', Queries.PrismicHeroBrand3HomepageHeroPrimary>
> &
  WithClassName;
/**
 * Hero data component: specifies the fragment and passes the appropriate data to the Hero component.
 */
const HeroData: FC<HeroDataProps> = ({ className, slice }) =>
  slice.variation === 'brand3HomepageHero' ? (
    <HomepageHero
      className={className}
      heading={slice.primary.heading}
      text={slice.primary.text || ''}
      primaryCta={{ text: slice.primary.primaryCtaButtonText || '', link: slice.primary.primaryCtaLink }}
      secondaryCta={{ text: slice.primary.secondaryCtaButtonText || '', link: slice.primary.secondaryCtaLink }}
      backgroundType={getBackgroundForVariation(slice.variation)}
      imageDesktop={slice.primary.imageDesktop}
      imageMobile={slice.primary.imageMobile}
    />
  ) : (
    <Hero
      className={className}
      heading={slice.primary.heading}
      text={slice.primary.text || ''}
      primaryCta={{ text: slice.primary.primaryCtaButtonText || '', link: slice.primary.primaryCtaLink }}
      secondaryCta={{ text: slice.primary.secondaryCtaButtonText || '', link: slice.primary.secondaryCtaLink }}
      backgroundType={getBackgroundForVariation(slice.variation)}
    />
  );

export default HeroData;
export const query = graphql`
  fragment HeroBrand5GlassSpinnerLime on PrismicHeroBrand5GlassSpinnerLime {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand2GlassSpinnerTeal on PrismicHeroBrand2GlassSpinnerTeal {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand5WaveLime on PrismicHeroBrand5WaveLime {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand3FlexSpinnerAzul on PrismicHeroBrand3FlexSpinnerAzul {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand5CardTwistTeal on PrismicHeroBrand5CardTwistTeal {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand2WaveTeal on PrismicHeroBrand2WaveTeal {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand4WaveMidnight on PrismicHeroBrand4WaveMidnight {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand3WeelLogoAzul on PrismicHeroBrand3WeelLogoAzul {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand5FlexSpinnerBase on PrismicHeroBrand5FlexSpinnerBase {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand5WeelLogoBase on PrismicHeroBrand5WeelLogoBase {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand5FlexSpinnerLime on PrismicHeroBrand5FlexSpinnerLime {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand5GlassSpinnerTeal on PrismicHeroBrand5GlassSpinnerTeal {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand4CardStackMidnight on PrismicHeroBrand4CardStackMidnight {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand3CardStackAzul on PrismicHeroBrand3CardStackAzul {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand3HomepageHero on PrismicHeroBrand3HomepageHero {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageDesktop {
        ...Image
      }
      imageMobile {
        ...Image
      }
    }
  }

  fragment Hero on PrismicSliceType {
    ...HeroBrand5GlassSpinnerLime
    ...HeroBrand2GlassSpinnerTeal
    ...HeroBrand5WaveLime
    ...HeroBrand3FlexSpinnerAzul
    ...HeroBrand5CardTwistTeal
    ...HeroBrand2WaveTeal
    ...HeroBrand4WaveMidnight
    ...HeroBrand3WeelLogoAzul
    ...HeroBrand5FlexSpinnerBase
    ...HeroBrand5WeelLogoBase
    ...HeroBrand5FlexSpinnerLime
    ...HeroBrand5GlassSpinnerTeal
    ...HeroBrand4CardStackMidnight
    ...HeroBrand3CardStackAzul
    ...HeroBrand3HomepageHero
  }
`;
