/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { brand1 } from '../../../design/colors/brand';
import { Background, Panel } from '../../../design/containers/panel/Panel';
import { breakpointSmall } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodySpan, BodyP } from '../../../design/typography/Typography';
import { WithTestId } from '../../../utlityTypes';

const Card = styled(Panel)`
  grid-column: span 2;
  padding: ${spacings['24px']};
  ${breakpointSmall(
    css`
      grid-column: span 1;
      padding: ${spacings['48px']};
    `
  )};
`;
Card.defaultProps = { borderRadius: `${spacings['12px']}` };

export const Quote = styled(BodyP)`
  padding-bottom: ${spacings['32px']};
`;

export const Name = styled(BodySpan)`
  color: ${brand1};
`;

export type QuoteData = {
  author?: string;
  company?: string;
  quote?: string;
  role?: string;
  background?: Background;
};

type QuoteProps = QuoteData & WithTestId;
/**
 * Renders QuoteCard Comp.
 */
const QuoteCard: FC<QuoteProps> = ({ quote, author, role, company, background, 'data-testid': testId }) => (
  <Card background={background} data-testid={testId}>
    {quote && (
      <Quote type="quotes" textAlign="left">
        "{quote}"
      </Quote>
    )}
    {author && <Name type="bodyLBold">{author}</Name>}
    {role && <BodySpan type="bodyLRegular"> | {role}</BodySpan>}
    {company && <BodySpan type="bodyLRegular">, {company}</BodySpan>}
  </Card>
);

export default QuoteCard;
