import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { Background, Panel } from '../../design/containers/panel/Panel';
import BaseColumnSmallRowContainer from '../../design/containers/responsive/BaseColumnSmallRowContainer';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import Icon, { IconType } from '../../design/icons/Icon';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import Heading2RichText from '../../design/typography/Heading2';
import { Heading3, BodyP, Heading2 } from '../../design/typography/Typography';
import bronzeTier from '../../images/bronze_tier.png';
import goldTier from '../../images/gold_tier.png';
import silverTier from '../../images/silver_tier.png';
import { WithClassName, WithTestId } from '../../utlityTypes';

const CardsContainer = styled.div`
  display: grid;
  gap: ${spacings['24px']};
  ${breakpointSmall(css`
    grid-template-columns: 2fr 1fr;
  `)}
`;

const CardColumnRight = styled.div`
  gap: ${spacings['24px']};
  display: grid;
`;

const RowSmallContainer = styled(BaseColumnSmallRowContainer)`
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
`;

const BadgeContainer = styled(BaseColumnSmallRowContainer)`
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`;

const Badge = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  text-align: center;
  min-width: 240px;
  height: 240px;
  background: url(${(props) => props.background}) no-repeat center / contain;
  padding: ${spacings['32px']};
`;

const BadgeInner = styled.div`
  background: white;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin-top: -${spacings['12px']};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextSubContent = styled(BodyP).attrs({ type: 'bodyLRegular', textAlign: 'center' })`
  max-width: 869px;
`;

const CardTextLeft = styled.div`
  margin-right: ${spacings['8px']};
`;

const Card = styled(Panel).attrs((props) => ({
  background: props.background || 'whiteSolid',
  borderRadius: spacings['12px'],
  padding: spacings['32px'],
}))``;

const TierSpacing = styled(Spacing)``;
TierSpacing.defaultProps = {
  top: { base: '12px', breakpointSmall: '32px' },
  right: { base: 'none', breakpointSmall: '8px' },
  left: { base: 'none', breakpointSmall: '8px' },
};

type Tier = { heading: string; text: string; featureText: string; subText: string };
type TierSectionProps = { background: string } & Tier & WithClassName & WithTestId;
const TierSection: FC<TierSectionProps> = ({
  className,
  heading,
  text,
  featureText,
  subText,
  background,
  'data-testid': testId,
}) => (
  <Badge className={className} background={background} data-testid={testId}>
    <BadgeInner>
      <BodyP type="bodyLBold">{heading}</BodyP>
      <BodyP type="bodySRegular">{text}</BodyP>
      <Spacing top="8px" bottom="8px">
        <Heading2 textAlign="center">{featureText}</Heading2>
      </Spacing>
      <BodyP type="bodySRegular">{subText}</BodyP>
    </BadgeInner>
  </Badge>
);

const TierSectionWithSpacing: FC<TierSectionProps> = (props) => (
  <TierSpacing>
    <TierSection {...props} />
  </TierSpacing>
);

type Card = { icon: IconType; heading: string; text: string };
type CardContentProps = { reverse?: boolean } & Card & WithClassName;
const CardContent: FC<CardContentProps> = ({ className, heading, text, icon, reverse }) => (
  <RowSmallContainer className={className}>
    <CardTextLeft>
      <Heading3 textAlign="left">{heading}</Heading3>
      <Spacing top="12px">
        <BodyP type="bodyMRegular">{text}</BodyP>
      </Spacing>
    </CardTextLeft>
    <Icon type={icon} reverse={reverse} />
  </RowSmallContainer>
);

type LeftCardProps = {
  bronze: Tier;
  silver: Tier;
  gold: Tier;
} & Card &
  WithClassName;
const LeftCard: FC<LeftCardProps> = ({ className, heading, text, icon, bronze, silver, gold }) => (
  <Card className={className} shadow="bigShadowStyles" data-testid="left-card">
    <CardContent heading={heading} text={text} icon={icon} />
    <Spacing top={{ base: '12px', breakpointSmall: 'none' }}>
      <BadgeContainer>
        <TierSectionWithSpacing background={bronzeTier} {...bronze} data-testid="bronze-tier-section" />
        <TierSectionWithSpacing background={silverTier} {...silver} data-testid="silver-tier-section" />
        <TierSectionWithSpacing background={goldTier} {...gold} data-testid="gold-tier-section" />
      </BadgeContainer>
    </Spacing>
  </Card>
);

type RightCardProps = { background: Background; reverse?: boolean } & Card & WithClassName & WithTestId;
const RightCard: FC<RightCardProps> = ({
  className,
  heading,
  text,
  icon,
  background,
  reverse,
  'data-testid': testId,
}) => (
  <Card className={className} background={background} data-testid={testId}>
    <CardContent heading={heading} text={text} icon={icon} reverse={reverse} />
  </Card>
);

type TwoColumnThreeCardsProps = {
  heading: PrismicRichTextType;
  text: string;
  leftCard: LeftCardProps;
  topRightCard: Card;
  bottomRightCard: Card;
} & WithClassName;
/**
 * Renders TwoColumnThreeCards slice.
 */
const TwoColumnThreeCards: FC<TwoColumnThreeCardsProps> = ({
  className,
  heading,
  text,
  leftCard,
  topRightCard,
  bottomRightCard,
}) => (
  <SliceContainer className={className} background="brand5Solid">
    <CenteredSliceContentContainer maxWidth="1286px">
      <Spacing bottom="24px">
        <Heading2RichText textAlign="center">{heading}</Heading2RichText>
      </Spacing>
      <Spacing bottom="48px">
        <TextSubContent>{text}</TextSubContent>
      </Spacing>
      <CardsContainer>
        <LeftCard {...leftCard} />
        <CardColumnRight>
          <RightCard {...topRightCard} background="brand2Solid" reverse data-testid="top-right-card" />
          <RightCard {...bottomRightCard} background="brand3Solid" reverse data-testid="bottom-right-card" />
        </CardColumnRight>
      </CardsContainer>
    </CenteredSliceContentContainer>
  </SliceContainer>
);

export default TwoColumnThreeCards;
