import React, { FC, PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { WithTestId } from '../../../utlityTypes';
import { breakpointMedium, breakpointSmall } from '../../responsive/breakpoints';
import {
  VerticalPadding,
  sliceContentContainerVerticalPaddingStyles,
  MaxWidth,
  sliceContentContainerMaxWidthStyles,
} from './sliceContentContainerStyles';

type SideSliceOuterContentContainerProps = { maxWidth?: MaxWidth };
const SideSliceOuterContentContainer = styled.div<SideSliceOuterContentContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) => sliceContentContainerMaxWidthStyles(props.maxWidth)}

  ${breakpointSmall(css`
    flex-direction: row;
  `)}
`;

export type Side = 'left' | 'right';
type SideSliceInnerContentContainerProps = { side?: Side; verticalPadding?: VerticalPadding } & WithTestId;
const SideSliceInnerContentContainer = styled.div<SideSliceInnerContentContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => sliceContentContainerVerticalPaddingStyles(props.verticalPadding)}

  ${(props) =>
    breakpointMedium(css`
      align-items: ${props.side === 'right' ? 'flex-end' : 'flex-start'};
      align-self: ${props.side === 'right' ? 'flex-end' : 'flex-start'};
      order: ${props.side === 'right' ? '1' : '0'};
      flex: 0 0 50%;
    `)}
`;
SideSliceInnerContentContainer.defaultProps = { 'data-testid': 'side-slice-content-container' };

type SideSliceContentContainerProps = { otherSideContent?: ReactNode } & SideSliceOuterContentContainerProps &
  SideSliceInnerContentContainerProps;
/**
 * Container for left or right slice content.
 */
export const SideSliceContentContainer: FC<PropsWithChildren<SideSliceContentContainerProps>> = ({
  children,
  side,
  verticalPadding,
  maxWidth,
  otherSideContent,
}) => (
  <SideSliceOuterContentContainer maxWidth={maxWidth}>
    <SideSliceInnerContentContainer side={side} verticalPadding={verticalPadding}>
      {children}
    </SideSliceInnerContentContainer>
    {otherSideContent}
  </SideSliceOuterContentContainer>
);
