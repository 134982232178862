import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
// import { generatePrismicLink } from '../../link/data/Link.generateData';
import Footer from '../Footer';

type FooterDataProps = { id?: string | null };
/**
 * Fetches data for footer and renders Footer component.
 */
const FooterData: FC<FooterDataProps> = ({ id }) => {
  const allFooters: Queries.FooterQuery = useStaticQuery(graphql`
    fragment FooterSection on PrismicFooterSectionDefault {
      primary {
        heading
      }
      items {
        text
        link {
          link_type
          url
        }
      }
    }

    query Footer {
      allPrismicFooter {
        nodes {
          prismicId
          data {
            contentLine1
            contentLine2
            contentParagraph
            slices {
              ...FooterSection
            }
          }
        }
      }
    }
  `);

  const footer = allFooters.allPrismicFooter.nodes.find(({ prismicId }) => prismicId === id);
  const sections = footer?.data.slices.map((prismicSection) => ({
    heading: prismicSection.primary.heading || '',
    links: prismicSection.items.map((prismicLink) => ({ text: prismicLink.text || '', link: prismicLink.link })),
  }));
  const content = {
    contentLine1: footer?.data.contentLine1,
    contentLine2: footer?.data.contentLine2,
    contentParagraph: footer?.data.contentParagraph,
  };

  return sections ? <Footer sections={sections} content={content} /> : null;
};

export default FooterData;

export const query = graphql`
  fragment PageFooter on PrismicPageDataType {
    footer {
      id
    }
  }

  fragment BlogPostFooter on PrismicBlogPostDataType {
    footer {
      id
    }
  }

  fragment NotFoundPageFooter on Prismic404pageDataType {
    footer {
      id
    }
  }
`;
