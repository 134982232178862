import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { jump } from '../../design/animations/micro-interactions';
import { Button } from '../../design/components/button/Button';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import { spacings } from '../../design/spacing/Spacing';
import { WithClassName } from '../../utlityTypes';
import BlogCard from './PostCard/PostCard';

type CardGrid = { maxHeight?: number; reachedEnd: boolean };
const CardGrid = styled.div<CardGrid>`
  max-height: ${(props) => `${props.maxHeight}px`};
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacings['24px']};
  max-width: 1280px;
  margin: 0 auto;
  transition: max-height 0.5s ease;
  margin: ${spacings['48px']} 0 ${spacings['48px']} 0;
  ${breakpointSmall(
    css`
      grid-template-columns: 1fr 1fr;
    `
  )};
`;

const PostsGrid = styled(SliceContainer)`
  margin-bottom: ${spacings['24px']};
`;
PostsGrid.defaultProps = { background: 'brand5Solid' };

const LoadMoreButton = styled(Button)`
  max-width: fit-content;
  margin: 0 auto ${spacings['100px']};
  ${jump({ height: `${spacings['4px']}` })}
`;

type Post = { url: string; date: string; title: string; author: string; excerpt: string };
type BlogPostGridProps = { posts?: Post[] } & WithClassName;
/**
 * PostsGrid component renders paginated list of all blog posts.
 */
const BlogPostCards: FC<BlogPostGridProps> = ({ posts = [] }) => {
  const paginationAmount = 6;
  const cardGrid = useRef<HTMLHeadingElement>(null);
  const [numOfPostsToShow, setNumOfPostsToShow] = useState(paginationAmount);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(cardGrid?.current?.scrollHeight);

  const reachedEnd = numOfPostsToShow >= posts.length;
  const onLoadMore = () => setNumOfPostsToShow(numOfPostsToShow + paginationAmount);

  useEffect(() => {
    setMaxHeight(cardGrid?.current?.scrollHeight);
  }, [numOfPostsToShow]);

  useEffect(() => {
    const handleResize = () => setMaxHeight(cardGrid?.current?.scrollHeight);
    addEventListener('resize', handleResize);
    return () => removeEventListener('resize', handleResize);
  }, []);

  return (
    <PostsGrid>
      <CardGrid maxHeight={maxHeight} ref={cardGrid} reachedEnd={reachedEnd} data-testid="card-grid">
        {posts?.slice(0, numOfPostsToShow)?.map(({ url, date, title, author, excerpt }) => (
          <BlogCard key={url || ''} date={date} title={title} author={author} excerpt={excerpt} url={url} />
        ))}
      </CardGrid>
      {posts.length > 6 && !reachedEnd && (
        <LoadMoreButton onClick={onLoadMore} styleType="reverseTertiary" data-testid="load-more">
          Older articles
        </LoadMoreButton>
      )}
    </PostsGrid>
  );
};

export default BlogPostCards;
