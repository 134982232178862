import { createContext } from 'react';

export type OpenDropdowns = Record<string, boolean>;
export type ChangeDropdownStatus = (headerName: string, open?: boolean) => void;
type HeaderContextState = {
  dropdownsStatus: OpenDropdowns;
  changeDropdownStatus: ChangeDropdownStatus;
};

export const HeaderContext = createContext<HeaderContextState>({ dropdownsStatus: {}, changeDropdownStatus: () => {} });
