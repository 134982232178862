/* eslint-disable jsdoc/require-description-complete-sentence */
import { css } from 'styled-components';
import generateResponsiveStyles, { ResponsiveValue } from '../../responsive/generateResponsiveStyles';

type PaddingType = '48px' | '32px' | '24px' | '16px' | 'none';
type DirectionalPadding = {
  top?: ResponsiveValue<PaddingType>;
  right?: ResponsiveValue<PaddingType>;
  bottom?: ResponsiveValue<PaddingType>;
  left?: ResponsiveValue<PaddingType>;
};
type Padding = ResponsiveValue<PaddingType> | DirectionalPadding;
type PaddingDirection = 'top' | 'right' | 'bottom' | 'left';

const getDirectionalPaddingStyles = (padding: PaddingType, direction: PaddingDirection) =>
  `padding-${direction}: ${padding !== 'none' ? padding : '0'};`;

const isDirectionalPadding = (paddingValue: Padding): paddingValue is DirectionalPadding =>
  typeof paddingValue !== 'string' &&
  ('top' in paddingValue || 'right' in paddingValue || 'bottom' in paddingValue || 'left' in paddingValue);

const getResponsiveDirectionalPaddingStyles = (
  paddingValue: ResponsiveValue<PaddingType>,
  direction: PaddingDirection
) => generateResponsiveStyles(paddingValue, (padding) => getDirectionalPaddingStyles(padding, direction));

export type PaddingProps = {
  /**
   * Sets padding. There are 3 ways to specify padding.
   *
   * 1. Standard - This just sets the specified value all around at all breakpoints.
   * Eg.
   * ```
   * <Panel padding="24px">
   * ```
   *
   * 2. Responsive - This allows setting different values at different breakpoints, but all around.
   * Eg.
   * ```
   * <Panel padding={{ base: '24px', breakpointSmall: '32px' }}>
   * ```
   *
   * 3. Responsive and Directional - This allows setting a responsive value for each direction individually.
   * Eg.
   * ```
   * <Panel padding={{ left: { base: '24px', breakpointSmall: '32px' }, bottom: { base: 'none', breakpointSmall: '16px' } }}>
   * ```
   */
  padding?: Padding;
};
/**
 * Generates padding styles.
 */
export const getPaddingStyles = (padding?: Padding) => {
  if (!padding) return '';
  return isDirectionalPadding(padding)
    ? css`
        ${Object.entries(padding).map(([paddingDirection, paddingValue]) =>
          getResponsiveDirectionalPaddingStyles(paddingValue, paddingDirection as keyof typeof padding)
        )}
      `
    : css`
        ${getResponsiveDirectionalPaddingStyles(padding, 'top')}
        ${getResponsiveDirectionalPaddingStyles(padding, 'right')}
        ${getResponsiveDirectionalPaddingStyles(padding, 'bottom')}
        ${getResponsiveDirectionalPaddingStyles(padding, 'left')}
      `;
};
