import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { LinkType } from '../../../components/link/types';
import { Background } from '../../../design/containers/panel/Panel';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import FiveColumnsPanel from '../FiveColumnsPanel';

const getBackgroundForVariation = (variation: FiveColumnsPanelDataProps['slice']['variation']) => {
  const variationBackgroundMap: Record<typeof variation, Background> = {
    default: 'brand4WaveMidnightRight',
    brand5ExpressiveWave: 'brand5WaveLimeRight',
  };
  return variationBackgroundMap[variation];
};

type FiveColumnsPanelDataProps = SliceProps<
  'FiveColumnsPanel',
  | SliceVariation<'default', Queries.PrismicFiveColumnsPanelDefaultPrimary>
  | SliceVariation<'brand5ExpressiveWave', Queries.PrismicFiveColumnsPanelBrand5ExpressiveWavePrimary>
> &
  WithClassName;
/**
 * FiveColumnsPanel data component: specifies the fragment and passes the appropriate data to the FiveColumnsPanel component.
 */
const FiveColumnsPanelData: FC<FiveColumnsPanelDataProps> = ({ className, slice }) => {
  const defaultVariation = slice?.variation === 'default';
  const brand5ExpressiveWaveVariation = slice?.variation === 'brand5ExpressiveWave';
  return (
    <FiveColumnsPanel
      className={className}
      cardOne={{
        heading: slice.primary.cardOneHeading || '',
        text: slice.primary.cardOneText || '',
        icon: defaultVariation ? slice?.primary?.cardOneIcon?.url : '',
      }}
      cardTwo={{
        heading: slice.primary.cardTwoHeading || '',
        text: slice.primary.cardTwoText || '',
        icon: slice.primary.cardTwoIcon?.url || '',
      }}
      cardThree={{
        heading: slice.primary.cardThreeHeading || '',
        text: slice.primary.cardThreeText || '',
        icon: slice.primary.cardThreeIcon?.url || '',
      }}
      cardFour={{
        heading: slice.primary.cardFourHeading || '',
        text: slice.primary.cardFourText || '',
        icon: slice?.primary?.cardFourIcon?.url || '',
      }}
      cardFive={{
        heading: slice.primary.cardFiveHeading || '',
        text: slice.primary.cardFiveText || '',
        icon: brand5ExpressiveWaveVariation ? slice?.primary?.cardFiveIcon?.url : '',
      }}
      panel={
        defaultVariation
          ? {
              text: slice?.primary?.panelText,
              cta: { text: slice.primary?.ctaText || '', link: slice.primary?.ctaLink },
              image: defaultVariation ? slice.primary.panelImage : null,
            }
          : undefined
      }
      headingContent={{
        heading: slice.primary.heading,
        text: brand5ExpressiveWaveVariation ? slice?.primary?.text : '',
        primaryCta: brand5ExpressiveWaveVariation
          ? ({
              text: slice?.primary?.primaryCtaButtonText,
              link: slice?.primary?.primaryCtaButtonLink,
            } as LinkType)
          : null,
        secondaryCta: brand5ExpressiveWaveVariation
          ? ({
              text: slice?.primary?.secondaryCtaButtonText,
              link: slice?.primary?.secondaryCtaButtonLink,
            } as LinkType)
          : null,
      }}
      backgroundType={getBackgroundForVariation(slice.variation)}
    />
  );
};

export default FiveColumnsPanelData;

export const query = graphql`
  fragment FiveColumnsPanelDefault on PrismicFiveColumnsPanelDefault {
    primary {
      cardOneIcon {
        url
      }
      cardTwoIcon {
        url
      }
      cardThreeIcon {
        url
      }
      cardFourIcon {
        url
      }
      cardFiveHeading
      cardFourHeading
      cardOneHeading
      cardThreeHeading
      cardTwoHeading
      cardFiveText
      cardFourText
      cardOneText
      cardThreeText
      cardTwoText
      heading {
        ...RichText
      }
      panelText {
        ...RichText
      }
      panelImage {
        ...Image
      }
      ctaText
      ctaLink {
        ...PrismicLink
      }
    }
    variation
  }

  fragment FiveColumnsPanelBrand5ExpressiveWave on PrismicFiveColumnsPanelBrand5ExpressiveWave {
    primary {
      cardTwoIcon {
        url
      }
      cardThreeIcon {
        url
      }
      cardFourIcon {
        url
      }
      cardFiveIcon {
        url
      }
      cardFiveHeading
      cardFourHeading
      cardOneHeading
      cardThreeHeading
      cardTwoHeading
      cardFiveText
      cardFourText
      cardOneText
      cardThreeText
      cardTwoText
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaButtonLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaButtonLink {
        ...PrismicLink
      }
    }
    variation
  }

  fragment FiveColumnsPanel on PrismicPageDataSlicesSlicesType {
    ...FiveColumnsPanelDefault
    ...FiveColumnsPanelBrand5ExpressiveWave
  }
`;
