import { css } from 'styled-components';

export const mediumShadowStyles = css`
  box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.2);
`;

export const bigShadowStyles = css`
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0531481),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185), 0px 20px 13px rgba(0, 0, 0, 0.035),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);
`;

export const blogHeaderShadow = css`
  box-shadow: 0 -48px 0px -15px rgb(6, 68, 78, 0.2);
`;

export const blogWysiwygShadow = css`
  box-shadow: 0px 4px 12px rgba(120, 144, 156, 0.2), 0px 15px 20px rgba(120, 144, 156, 0.01);
`;
