import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import ContentInfoBlock from '../ContentInfoBlock';

type ContentInfoBlockProps = SliceProps<
  'contentInfoBlock',
  SliceVariation<'default', Queries.PrismicContentInfoBlockDefaultPrimary, Queries.PrismicContentInfoBlockDefaultItem>
> &
  WithClassName;

/**
 * Content info block data component: specifies the fragment and passes the appripriate data to the ContentInfoBlock component.
 */
const ContentInfoBlockData: FC<ContentInfoBlockProps> = ({ className, slice }) => (
  <ContentInfoBlock
    className={className}
    heading={slice.primary?.heading}
    text={slice.primary?.text || ''}
    primaryCta={{ text: slice.primary?.primaryCtaButtonText || '', link: slice.primary?.primaryCtaButtonLink }}
    secondaryCta={{ text: slice.primary?.secondaryCTAButtonText || '', link: slice.primary?.secondaryCTAButtonLink }}
    image={slice.primary?.image}
    items={slice.items || []}
  />
);

export default ContentInfoBlockData;

export const query = graphql`
  fragment ContentInfoBlock on PrismicContentInfoBlockDefault {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaButtonLink {
        ...PrismicLink
      }
      secondaryCTAButtonText
      secondaryCTAButtonLink {
        ...PrismicLink
      }
      image {
        ...Image
      }
    }
    items {
      heading
      text
      ctaButtonText
      ctaLink {
        ...PrismicLink
      }
    }
  }
`;
