import { WrapRootElementBrowserArgs } from 'gatsby';
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';
import React, { FC } from 'react';
import linkResolver from '../gatsbyHelpers/linkResolver';
import BlogPost from '../src/templates/blog_post/BlogPost';
import Page from '../src/templates/page/Page';

/**
 * Required for prismic previews: https://prismic.io/docs/technologies/previews-gatsby#1.-set-the-preview-provider.
 */
export const WrapWithPrismicPreviewProvider: FC<WrapRootElementBrowserArgs> = ({ element }) =>
  !process.env.GATSBY_PRISMIC_REPOSITORY_NAME ? null : (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            page: Page,
            blog_post: BlogPost,
          }),
        },
      ]}
    >
      {element}
    </PrismicPreviewProvider>
  );
