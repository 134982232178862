import React, { FC } from 'react';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { WithClassName } from '../../utlityTypes';
import BottomInfoBlock, { BottomContentProps } from './BottomInfoBlock';
import TopInfoBlock, { TopContentProps } from './TopContentInfoBlock';

type Props = TopContentProps & BottomContentProps & WithClassName;
/**
 * Renders ContentInfoBlock slice.
 */
const ContentInfoBlock: FC<Props> = ({ className, heading, text, primaryCta, secondaryCta, image, items }) => (
  <SliceContainer className={className} background="brand5Solid">
    <TopInfoBlock heading={heading} text={text} primaryCta={primaryCta} secondaryCta={secondaryCta} />
    <BottomInfoBlock items={items} image={image} />
  </SliceContainer>
);

export default ContentInfoBlock;
