import React, { FC } from 'react';
import { RawImage } from '../../components/image/rawImage/RawImage';
import { WithClassName } from '../../utlityTypes';
import { marketingIcons, marketingIconsReverse } from './marketing/marketingIcons';

const allIcons = { ...marketingIcons };
const allIconsReverse = { ...marketingIconsReverse };
export type IconType = keyof typeof allIcons;

/**
 * Gets all icon names.
 */
export const getAllIconNames = () => Object.keys(allIcons);

const getNormalIcon = (iconName: IconType) => allIcons[iconName];
const getReverseIcon = (iconName: IconType) => allIconsReverse[iconName];
export type WithIconReverse = {
  /**
   * When true, gets a 'reversed' version of the icon that will work on dark backgrounds.
   */
  reverse?: boolean;
};

const getIcon = (iconName: IconType, reverse?: WithIconReverse['reverse']) =>
  reverse ? getReverseIcon(iconName) : getNormalIcon(iconName);

type IconProps = { type: IconType } & WithClassName & WithIconReverse;
/**
 * Renders icon. Can specify `type` prop to choose which icon gets rendered.
 */
const Icon: FC<IconProps> = ({ className, type, reverse }) => (
  <RawImage className={className} src={getIcon(type, reverse)} alt={`${type} Icon`} />
);

export default Icon;
