import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { WithClassName, WithTestId } from '../../../utlityTypes';
import * as shadows from '../../colors/shadows';
import generateResponsiveStyles, { isBreakpointMap, ResponsiveValue } from '../../responsive/generateResponsiveStyles';
import * as backgrounds from './backgrounds';
import { getPaddingStyles, PaddingProps } from './paddingStyles';

export type Background = keyof typeof backgrounds;

const getBackground = (backgroundName: Background) => backgrounds[backgroundName];

/**
 * Gets background styles.
 */
export const getBackgroundStyles = (backgroundName: Background) => {
  const background = getBackground(backgroundName);
  return 'styles' in background ? background.styles : background;
};

const getBackgroundImageComponent = (backgroundName: ResponsiveValue<Background>) => {
  if (!isBreakpointMap(backgroundName)) {
    const background = getBackground(backgroundName);
    return 'ImageComponent' in background ? background.ImageComponent : undefined;
  }
  return undefined;
};

/**
 * Given a background name, returns if the background is dark or not.
 */
export const isDarkBackground = (backgroundName: Background) => getBackground(backgroundName).isDark;

type BorderRadius = '32px' | '24px' | '12px' | '0px';

const getBorderRadiusStyles = (borderRadius: BorderRadius) => css`
  border-radius: ${borderRadius};
`;

type ShadowType = keyof typeof shadows;
const getShadowStyles = (shadowType: ShadowType) => shadows[shadowType];

type PanelRootProps = {
  background?: ResponsiveValue<Background>;
  borderRadius?: BorderRadius;
  shadow?: ShadowType;
} & PaddingProps;
const PanelRoot = styled.div<PanelRootProps>`
  position: relative;
  ${(props) => getBorderRadiusStyles(props.borderRadius || '32px')}
  overflow: hidden;
  transform: translateZ(0);
  & > * {
    z-index: 1;
  }

  ${(props) =>
    props.background && generateResponsiveStyles(props.background, (background) => getBackgroundStyles(background))}
  ${(props) => props.shadow && getShadowStyles(props.shadow)}
  ${(props) => getPaddingStyles(props.padding)}
`;

type PanelProps = PanelRootProps & WithClassName & WithTestId;
/**
 * Renders panel.
 */
export const Panel: FC<PropsWithChildren<PanelProps>> = ({
  className,
  background,
  children,
  'data-testid': testId,
  ...panelProps
}) => {
  const ImageComponent = background && getBackgroundImageComponent(background);

  return (
    <PanelRoot className={className} background={background} data-testid={testId} {...panelProps}>
      {ImageComponent && <ImageComponent />}
      {children}
    </PanelRoot>
  );
};
