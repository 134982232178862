import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { grey500 } from '../../../design/colors/greyscale';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointSmall } from '../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../design/spacing/Spacing';
import { Heading2, BodySpan, BodyP } from '../../../design/typography/Typography';
import { WithClassName } from '../../../utlityTypes';
import { ItemProps, TwoColumnContainer } from './PricingColumns';

const CardPanel = styled(Panel)`
  padding: ${spacings['48px']};
  position: relative;
  flex: 1;
  align-self: center;
  max-width: 416px;
  min-width: 224px;

  ${breakpointSmall(
    css`
      align-self: stretch;
    `
  )};
`;
CardPanel.defaultProps = { shadow: 'bigShadowStyles', padding: '32px', borderRadius: '12px' };

const PricingPeriod = styled(BodyP).attrs({ type: 'bodyLRegular', textAlign: 'left' })`
  color: ${grey500};
`;

type PriceProps = {
  price: ItemProps['price'];
  pricingPeriod: ItemProps['pricingPeriod'];
  pricingSubtext?: ItemProps['pricingSubtext'];
} & WithClassName;
/**
 * Renders the price and pricing period for the Price slice.
 */
const Price: FC<PriceProps> = ({ price, pricingPeriod, pricingSubtext, className }) => (
  <>
    <Spacing top="24px" bottom="24px">
      <TwoColumnContainer>
        <Heading2 textAlign="left">{price}</Heading2>
        <PricingPeriod>/{pricingPeriod}</PricingPeriod>
      </TwoColumnContainer>
    </Spacing>
    <BodySpan className={className} type="bodyLRegular" textAlign="left">
      {pricingSubtext}
    </BodySpan>
  </>
);

export default Price;
