import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { siteUrl } from '../../../config/site';

export type SEOProps = { title?: string | ''; description?: string | ''; ogImage?: string | null };
/**
 * Exports SEO comp.
 */
export const SEO: FC<SEOProps> = ({ title, description, ogImage }) => {
  const defaultValues = {
    title: 'All-in-One Virtual Cards & Business Expense Management | Weel',
    description:
      "Weel's All-in-One virtual cards and business expense tracker app gives you real-time visibility and control of spending. Book a demo or try FREE for 14 days.",
    author: 'Weel',
    ogImage: 'src/images/brand/og-image.png',
    siteUrl,
  };

  //   const url = `${defaultValues.siteUrl}${location?.pathname || ''}`;

  const seo = {
    title: title || defaultValues.title,
    description: description || defaultValues.description,
    image: ogImage || defaultValues.ogImage,
    author: defaultValues.author,
    // url,
  };

  const organizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Weel',
    url: siteUrl,
    logo: '/Users/cassie/Documents/code_bases/weel-website/src/images/brand/icon.png',
    sameAs: [],
    // @todo add social links above
  };

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: defaultValues.siteUrl,
    headline: seo.title,
    inLanguage: 'en',
    mainEntityOfPage: defaultValues.siteUrl,
    description: seo.description,
    name: seo.author,
    author: {
      '@type': 'Person',
      name: seo.author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: seo.author,
    },
    copyrightYear: new Date().getFullYear(),
    creator: {
      '@type': 'Person',
      name: seo.author,
    },
    publisher: {
      '@type': 'Person',
      name: seo.author,
    },
    datePublished: '2019-01-18T10:30:00+01:00',
    // dateModified:'2019-01-18T10:30:00+01:00',
    image: {
      '@type': 'ImageObject',
      url: seo.image,
    },
  };

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="image" content={seo.image} />
      <meta name="description" content={seo.description} />
      <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
      <script type="application/ld+json">{JSON.stringify(organizationSchema)}</script>
      {/* {article && <script type="application/ld+json">{JSON.stringify(schema || schemaArticle)}</script>} */}
      {/* @todo add article schema for blog when done */}
      <link rel="icon" type="image/png" href="/images/brand/icon.png" />
    </Helmet>
  );
};
