import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall, breakpointMedium } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { WithClassName } from '../../utlityTypes';
import Steps from './Steps/Steps';
import TextColumn from './TextColumn/TextColumn';

type ContentType = {
  heading?: PrismicRichTextType | null;
  text?: PrismicRichTextType | null;
};

type StepsType = {
  step1?: string;
  step2?: string;
  step3?: string;
};

const TwoColGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacings['48px']};
  ${breakpointSmall(
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      gap: ${spacings['64px']};
    `
  )};
  ${breakpointMedium(
    css`
      gap: ${spacings['100px']};
    `
  )};
`;

type ApplePayProps = {
  leftContent?: ContentType;
  rightContent?: ContentType;
  steps?: StepsType;
} & WithClassName;
/**
 * Renders ApplePay slice.
 */
const ApplePay: FC<ApplePayProps> = ({ className, leftContent, rightContent, steps }) => {
  return (
    <SliceContainer className={className} background="grey200Solid">
      <CenteredSliceContentContainer>
        <Spacing bottom={{ base: '48px', breakpointSmall: '100px' }}>
          <TwoColGrid>
            <TextColumn {...leftContent} />
            <TextColumn {...rightContent} />
          </TwoColGrid>
        </Spacing>
        <Steps {...steps} />
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};
export default ApplePay;
