import { SliceZone } from '@prismicio/react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import PageContainer from '../../pageContainers/PageContainer';
import ApplePayData from '../../slices/applePay/data/ApplePay.queryData';
import BlogPostCardsData from '../../slices/blogPostCards/data/BlogPostCards.queryData';
import BottomCtaData from '../../slices/bottomCta/data/BottomCta.queryData';
import CompanyQuoteData from '../../slices/companyQuote/data/CompanyQuote.queryData';
import ContentInfoBlockData from '../../slices/contentInfoBlock/data/ContentInfoBlock.queryData';
import DemoData from '../../slices/demoForm/data/Demo.queryData';
import FaqsData from '../../slices/faqs/data/Faqs.queryData';
import FiveColumnsPanelData from '../../slices/fiveColumnsPanel/data/FiveColumnsPanel.queryData';
import HeroData from '../../slices/hero/data/Hero.queryData';
import LogosData from '../../slices/logos/data/Logo.queryData';
import PricingData from '../../slices/pricing/data/Pricing.queryData';
import QuoteData from '../../slices/quote/data/Quote.queryData';
import SocialProofData from '../../slices/socialProof/data/SocialProof.queryData';
import Steps from '../../slices/steps/data/Steps.queryData';
import ThreeColumnCardsData from '../../slices/threeColumnCards/data/ThreeColumnCards.queryData';
import ThreeColumnFiveCardsData from '../../slices/threeColumnFiveCards/data/ThreeColumnFiveCards.queryData';
import TitleData from '../../slices/title/data/Title.queryData';
import TwoColFloatingContentData from '../../slices/twoColFloatingContent/data/TwoColFloatingContent.queryData';
import TwoColumnContentData from '../../slices/twoColumnContent/data/TwoColumnContent.queryData';
import TwoColumnContentImageData from '../../slices/twoColumnContentImage/data/TwoColumnContentImage.queryData';
import TwoColumnFourCardsData from '../../slices/twoColumnFourCards/data/TwoColumnFourCards.queryData';
import TwoColumnThreeCardsData from '../../slices/twoColumnThreeCards/data/TwoColumnThreeCards.queryData';
import withBottomSpacing from '../../slices/utils/withBottomSpacing';
import WysiwygData from '../../slices/wysiwyg/data/Wysiwyg.queryData';

const components = {
  hero: withBottomSpacing(HeroData),
  quote: withBottomSpacing(QuoteData),
  faqs: withBottomSpacing(FaqsData),
  three_column_cards: withBottomSpacing(ThreeColumnCardsData),
  two_column_three_cards: withBottomSpacing(TwoColumnThreeCardsData),
  two_column_four_cards: withBottomSpacing(TwoColumnFourCardsData),
  three_column_five_cards: withBottomSpacing(ThreeColumnFiveCardsData),
  bottom_cta: withBottomSpacing(BottomCtaData),
  social_proof: withBottomSpacing(SocialProofData),
  two_column_content_image: withBottomSpacing(TwoColumnContentImageData),
  two_column_content: withBottomSpacing(TwoColumnContentData),
  two_col_floating_content: withBottomSpacing(TwoColFloatingContentData),
  five_columns_panel: withBottomSpacing(FiveColumnsPanelData),
  steps: withBottomSpacing(Steps),
  logos: withBottomSpacing(LogosData),
  content_info_block: withBottomSpacing(ContentInfoBlockData),
  title: withBottomSpacing(TitleData),
  apple_pay: withBottomSpacing(ApplePayData),
  pricing: withBottomSpacing(PricingData),
  company_quote: withBottomSpacing(CompanyQuoteData),
  demo: withBottomSpacing(DemoData),
  wysiwyg: withBottomSpacing(WysiwygData),
  blog_post_cards: withBottomSpacing(BlogPostCardsData),
};

/**
 * Renders Prismic Page document and maps slice data to be rendered by their components in order.
 */
const Page = ({ data }: PageProps<Queries.PageQuery>) => {
  const headerId = data.prismicPage?.data.header?.id || undefined;
  const footerId = data.prismicPage?.data.footer?.id || undefined;
  const slices = data.prismicPage?.data.slices;

  const seo = {
    title: data?.prismicPage?.data?.Title || '',
    description: data?.prismicPage?.data?.Description || '',
    ogImage: data?.prismicPage?.data?.Image?.url,
    // schema: @todo add later
  } as const;

  return (
    <PageContainer headerId={headerId} footerId={footerId} seoProps={seo}>
      <SliceZone slices={slices} components={components} />
    </PageContainer>
  );
};

export default withPrismicPreview(Page);

// export const Head = ({ data }: PageProps<Queries.PageQuery>) => {
//   // const seo = {
//   //   title: data?.prismicPage?.data?.Title || '',
//   //   description: data?.prismicPage?.data?.Description || '',
//   //   ogImage: data?.prismicPage?.data?.Image?.url,
//   //   // schema: @todo add later
//   // } as const;
//   // return <SEO {...seo} />;
// };
// @todo come back after launch for gatsby head api

export const query = graphql`
  query Page($url: String!) {
    prismicPage(url: { eq: $url }) {
      _previewable
      data {
        ...SEO
        ...PageHeader
        ...PageFooter
        slices {
          ...Slice
          ...Hero
          ...Quote
          ...Faqs
          ...ThreeColumnCards
          ...TwoColumnThreeCards
          ...TwoColumnFourCards
          ...TwoColumnContentImage
          ...TwoColumnContent
          ...TwoColFloatingContent
          ...ThreeColumnFiveCards
          ...BottomCta
          ...Steps
          ...Logos
          ...ContentInfoBlock
          ...FiveColumnsPanel
          ...Title
          ...ApplePay
          ...Pricing
          ...CompanyQuote
          ...Demo
          ...Wysiwyg
        }
        footer {
          id
        }
      }
    }
  }
`;
