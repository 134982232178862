import React, { FC, ReactElement } from 'react';
import { WithClassName, WithTestId } from '../../../utlityTypes';
import Spacing from '../../spacing/Spacing';
import BaseColumnSmallRowContainer from '../responsive/BaseColumnSmallRowContainer';

type Props = { cta1Placement: ReactElement; cta2Placement: ReactElement } & WithClassName & WithTestId;
/**
 * Renders two CTA buttons vertically stacked on mobile, horizontally on desktop.
 */
const TwoCtaPlacementContainer: FC<Props> = ({
  className,
  cta1Placement,
  cta2Placement,
  'data-testid': testId = 'two-cta-placement-container',
}) => (
  <BaseColumnSmallRowContainer className={className} data-testid={testId}>
    {cta1Placement}
    <Spacing top={{ base: '16px', breakpointSmall: 'none' }} left={{ breakpointSmall: '16px' }}>
      {cta2Placement}
    </Spacing>
  </BaseColumnSmallRowContainer>
);

export default TwoCtaPlacementContainer;
