/* eslint-disable react/no-unescaped-entities */
import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../../components/image/prismicImage/PrismicImage';
import { LinkType } from '../../../components/link/types';
import RichText, { PrismicRichTextType } from '../../../components/richText/RichText';
import { brand1 } from '../../../design/colors/brand';
import { ButtonLink } from '../../../design/components/button/Button';
import { OnVisible } from '../../../design/components/onVisible/OnVisible';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointSmall, breakpointXSmall, breakpointMedium } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { getFontWeight } from '../../../design/typography/base/Text';
import { WithTestId } from '../../../utlityTypes';
import CardsFrame from './CardsFrame';

const Container = styled(Panel)`
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(2px);
  display: grid;
  width: 100%;
  grid-template-areas:
    'text'
    'image'
    'button';
  gap: ${spacings['24px']};
  justify-items: center;
  ${breakpointXSmall(css`
    gap: ${spacings['48px']};
  `)};
  ${breakpointSmall(css`
    grid-template-areas:
      'image text'
      'image button';
    grid-template-columns: auto 1fr;
    gap: 0 ${spacings['48px']};
    align-items: center;
    justify-items: start;
    padding-left: ${spacings['48px']};
  `)};
  ${breakpointMedium(css`
    padding-left: ${spacings['64px']};
  `)};
`;
Container.defaultProps = {
  borderRadius: `${spacings['12px']}`,
  padding: { top: '48px', bottom: '48px', left: '24px', right: '24px' },
};
// NOTE: in sass, can usually pass variable to rgba($white, 0.5) but doesn't seem to work for styled comps. Using raw RGB values
// NOTE: 88px value was not in padding system. Passing props that exist and extending in styled comp for now

const StructuredText = styled(RichText)`
  padding-bottom: ${spacings['24px']};
  max-width: 500px;
  grid-area: text;
  strong {
    color: ${brand1};
    font-weight: ${getFontWeight('400')};
  }
  ${breakpointSmall(css`
    padding-bottom: 0;
  `)};
`;
// StructuredText.defaultProps = {
//   style: {
//     fontWeight: '400',
//     fontSize: '18pt',
//     letterSpacing: '0',
//     lineHeight: '120%',
//   },
// };

const Button = styled(ButtonLink)`
  width: fit-content;
  grid-area: button;
`;

type PanelData = {
  text?: PrismicRichTextType;
  cta?: LinkType;
  image?: ComponentProps<typeof PrismicImage>['image'];
};

type PanelProps = PanelData & WithTestId;
/**
 * Renders PanelSection Comp.
 */
const PanelSection: FC<PanelProps> = ({ text, cta, image, 'data-testid': testId }) => (
  <OnVisible fadeUp threshold={0.1}>
    <Container data-testid={testId}>
      <CardsFrame image={image} />
      {text && <StructuredText textType="heading4" field={text} />}
      {cta?.link && cta?.text && (
        <Button size="large" link={cta.link}>
          {cta.text}
        </Button>
      )}
    </Container>
  </OnVisible>
);

export default PanelSection;
