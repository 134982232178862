/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall, breakpointMedium } from '../../design/responsive/breakpoints';
import { spacings } from '../../design/spacing/Spacing';
import { Heading2 } from '../../design/typography/Typography';
import { WithClassName } from '../../utlityTypes';
import Step from './Step/Step';

const StepsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacings['48px']};

  ${breakpointSmall(
    css`
      grid-template-columns: 1fr 1fr;
      gap: ${spacings['64px']};
    `
  )};

  ${breakpointMedium(
    css`
      gap: ${spacings['144px']};
      grid-auto-rows: 1fr;
    `
  )}
`;

type StepsProps = {
  title: string | '';
  items: Array<Queries.PrismicStepsDefaultItem> | [];
} & WithClassName;

/**
 * Renders Steps slice.
 */
const Steps: FC<StepsProps> = ({ title, items, className }) => {
  return (
    <SliceContainer className={className}>
      <CenteredSliceContentContainer>
        <StepsWrapper>
          <Heading2 textAlign="left">{title}</Heading2>
          <div>
            {items.map((item, index) => (
              <Step key={item.Heading} {...item} index={index} />
            ))}
          </div>
        </StepsWrapper>
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};

export default Steps;
