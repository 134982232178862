import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Logos from '../Logos';

type LogosProps = SliceProps<
  'logos',
  SliceVariation<'default', Queries.PrismicLogosDefaultPrimary, Queries.PrismicLogosDefaultItem>
> &
  WithClassName;

/**
 * Logos data component: specifies the fragment and passes the appropriate data to the Logos component.
 */
const LogosData: FC<LogosProps> = ({ className, slice }) => (
  <Logos
    className={className}
    heading={slice.primary.heading}
    text={slice.primary.text || ''}
    items={slice.items || []}
  />
);

export default LogosData;

export const query = graphql`
  fragment Logos on PrismicLogosDefault {
    primary {
      heading {
        ...RichText
      }
      text
    }
    items {
      logo {
        ...Image
      }
    }
  }
`;
