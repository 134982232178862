import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import generateResponsiveStyles, { ResponsiveValue } from '../../../design/responsive/generateResponsiveStyles';
import { WithClassName } from '../../../utlityTypes';

type Visibility = 'show' | 'hide';
const getVisibilityStyles = (visibility: Visibility) => css`
  display: ${visibility === 'hide' ? 'none' : 'block'};
`;

type GatsbyImageWithResponsiveHideShowProps = { visibility?: ResponsiveValue<Visibility> };
const GatsbyImageWithResponsiveHideShow = styled(GatsbyImage)<GatsbyImageWithResponsiveHideShowProps>`
  && {
    ${({ visibility = 'show' }) => generateResponsiveStyles(visibility, getVisibilityStyles)}
  }
`;

type OtherGatsbyImageProps = Omit<ComponentProps<typeof GatsbyImage>, 'className' | 'image' | 'alt'>;
type PrismicImageProps = {
  image: Pick<Queries.ImageFragment, 'gatsbyImageData' | 'alt'> | null;
} & WithClassName &
  OtherGatsbyImageProps &
  GatsbyImageWithResponsiveHideShowProps;
/**
 * Renders an image from prismic.
 */
export const PrismicImage: FC<PrismicImageProps> = ({ className, image, ...otherProps }) => {
  if (!image) return null;
  return (
    <GatsbyImageWithResponsiveHideShow
      className={className}
      image={image.gatsbyImageData as unknown as IGatsbyImageData}
      alt={image.alt || 'picture'}
      {...otherProps}
    />
  );
};
