/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Background, Panel } from '../../../design/containers/panel/Panel';
import { breakpointSmall } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP, Heading2 } from '../../../design/typography/Typography';
import { WithTestId } from '../../../utlityTypes';

const Card = styled(Panel)`
  grid-column: span 2;
  padding: ${spacings['24px']};
  ${breakpointSmall(
    css`
      grid-column: span 1;
      padding: ${spacings['48px']};
    `
  )};
`;
Card.defaultProps = { borderRadius: `${spacings['12px']}` };

const Heading = styled(Heading2)`
  padding-bottom: ${spacings['24px']};
`;

type ContentData = {
  heading?: string;
  text?: string;
  background?: Background;
};
type ContentProps = ContentData & WithTestId;

/**
 * Renders ContentCard comp.
 */
const ContentCard: FC<ContentProps> = ({ heading, text, background, 'data-testid': testId }) => (
  <Card background={background} data-testid={testId}>
    {heading && <Heading textAlign="left">{heading}</Heading>}
    {text && <BodyP type="bodyLRegular">{text}</BodyP>}
  </Card>
);

export default ContentCard;
