import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Wysiwyg from '../Wysiwyg';

type WysiwygDataProps = SliceProps<'Wysiwyg', SliceVariation<'default', Queries.WysiwygFragment['primary']>> &
  WithClassName;
/**
 * Wysiwyg data component: specifies the fragment and passes the appropriate data to the Wysiwyg component.
 */
const WysiwygData: FC<WysiwygDataProps> = ({ className, slice }) => (
  <Wysiwyg className={className} text={slice.primary?.content} />
);

export default WysiwygData;

export const query = graphql`
  fragment Wysiwyg on PrismicWysiwygDefault {
    primary {
      content: text {
        ...RichText
      }
    }
  }
`;
