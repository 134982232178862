import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { IconType } from '../../../design/icons/Icon';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Demo, { IconProps } from '../Demo';

type DemoProps = SliceProps<
  'demo',
  SliceVariation<'default', Queries.PrismicDemoDefaultPrimary, Queries.PrismicDemoDefaultItem>
> &
  WithClassName;

/**
 * Demo data component: specifies the fragment and passes the appropriate data to the Demo component.
 */
const DemoData: FC<DemoProps> = ({ className, slice }) => {
  const icons: IconProps[] = [
    { image: slice.primary?.topLeftIcon as IconType, text: slice.primary?.topLeftText || '' },
    { image: slice.primary?.bottomLeftIcon as IconType, text: slice.primary?.bottomLeftText || '' },
    { image: slice.primary?.topRightIcon as IconType, text: slice.primary?.topRightText || '' },
    { image: slice.primary?.bottomRightIcon as IconType, text: slice.primary?.bottomRightText || '' },
  ];
  return (
    <Demo
      className={className}
      heading={slice.primary.heading}
      text={slice.primary?.text || ''}
      form={{
        subHeading: slice.primary?.subHeading || '',
        icons: icons || [],
        partnershipHeading: slice.primary?.partnershipHeading || '',
        hubspot: {
          formType: slice.primary?.formType || 'Hubspot Form',
          id: slice.primary?.hubspotFormId || '',
          link: slice.primary?.hubspotMeetingLink,
          trackingEvent: slice.primary?.trackingEventName || '',
        },
        logos: slice.items,
      }}
    />
  );
};

export default DemoData;

export const query = graphql`
  fragment Demo on PrismicDemoDefault {
    primary {
      heading {
        ...RichText
      }
      text
      subHeading
      topLeftIcon
      topLeftText
      bottomLeftIcon
      bottomLeftText
      topRightIcon
      topRightText
      bottomRightIcon
      bottomRightText
      partnershipHeading
      formType
      hubspotFormId
      hubspotMeetingLink {
        ...PrismicLink
      }
      trackingEventName
    }
    items {
      logo {
        ...Image
      }
    }
  }
`;
