/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import styled from 'styled-components';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { grey400 } from '../../design/colors/greyscale';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { spacings } from '../../design/spacing/Spacing';
import { WithClassName } from '../../utlityTypes';

const WysiwygText = styled(RichText)`
  width: 100%;
  padding: ${spacings['24px']} 0;
  * {
    text-align: left;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 16px 0;
  }
`;

const CenteredContainer = styled(CenteredSliceContentContainer)`
  width: 100%;
`;
const SliceContainerAlt = styled(SliceContainer)`
  & + .wysiwyg-slice {
    border-top: 1px solid ${grey400};
  }
`;
// @todo build these styles into the RichText comp?
type WysiwygProps = {
  text: PrismicRichTextType;
} & WithClassName;

/**
 * Renders Wysiwyg slice.
 */
const Wysiwyg: FC<WysiwygProps> = ({ text }) => {
  return (
    <SliceContainerAlt className="wysiwyg-slice" borderRadius="0px">
      <CenteredContainer>
        <WysiwygText field={text} />
      </CenteredContainer>
    </SliceContainerAlt>
  );
};

export default Wysiwyg;
