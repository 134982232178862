/* eslint-disable react/no-unescaped-entities */
import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../components/image/prismicImage/PrismicImage';
import { Background } from '../../design/containers/panel/Panel';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import { spacings } from '../../design/spacing/Spacing';
import { WithClassName } from '../../utlityTypes';
import ContentCard from './ContentCard/ContentCard';
import QuoteCard from './QuoteCard/QuoteCard';

const Image = styled(PrismicImage)`
  grid-column: span 2;
  border-radius: ${spacings['12px']};
  ${breakpointSmall(
    css`
      grid-column: span 2;
      border-radius: ${spacings['12px']};
    `
  )};
`;

export const TwoColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacings['24px']};
  grid-auto-rows: 1fr;
  ${breakpointSmall(
    css`
      grid-template-columns: 1fr 1fr;
    `
  )};
`;

type QuoteData = {
  author?: string;
  company?: string;
  quote?: string;
  role?: string;
  background?: Background;
};

type ContentData = {
  heading?: string;
  text?: string;
  background?: Background;
};

type TwoColumnContentProps = {
  topLeftCard: ContentData;
  topRightCard: QuoteData;
  middleLeftCard: QuoteData;
  middleRightCard: ContentData;
  image: ComponentProps<typeof PrismicImage>['image'];
  bottomLeftCard: ContentData;
  bottomRightCard: QuoteData;
} & WithClassName;
/**
 * Renders TwoColumnContent slice.
 */
const TwoColumnContent: FC<TwoColumnContentProps> = ({
  topLeftCard,
  topRightCard,
  middleLeftCard,
  middleRightCard,
  image,
  bottomLeftCard,
  bottomRightCard,
  className,
}) => {
  return (
    <SliceContainer className={className}>
      <CenteredSliceContentContainer>
        <TwoColumnsWrapper>
          <ContentCard {...topLeftCard} background="brand5Solid" data-testid="top-left-card" />
          <QuoteCard {...topRightCard} background="lightOpaqueCards" data-testid="top-right-card" />
          <QuoteCard {...middleLeftCard} background="darkOpaqueCards" data-testid="middle-left-card" />
          <ContentCard {...middleRightCard} background="brand5Solid" data-testid="middle-right-card" />
          {image && <Image image={image} data-testid="image" />}
          <ContentCard {...bottomLeftCard} background="brand5Solid" data-testid="bottom-left-card" />
          <QuoteCard {...bottomRightCard} background="lightOpaqueCards" data-testid="bottom-right-card" />
        </TwoColumnsWrapper>
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};

export default TwoColumnContent;
