import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Steps from '../Steps';

type StepsDataProps = SliceProps<
  'Steps',
  SliceVariation<'default', Queries.PrismicStepsDefaultPrimary, Queries.PrismicStepsDefaultItem>
> &
  WithClassName;
/**
 * Steps data component: specifies the fragment and passes the appropriate data to the Steps component.
 */
const StepsData: FC<StepsDataProps> = ({ className, slice }) => (
  <Steps className={className} title={slice.primary.Title || ''} items={slice.items || []} />
);

export default StepsData;

/**
 * @todo Rename Title to heading to be consistent and rename Heading uppercase H to heading lowercase h.
 * @todo Convert Title to rich text.
 */
export const query = graphql`
  fragment Steps on PrismicStepsDefault {
    primary {
      Title
    }
    items {
      Heading
      Text
    }
  }
`;
