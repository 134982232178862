import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../components/image/prismicImage/PrismicImage';
import { LinkType } from '../../components/link/types';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { getRichTextFieldText } from '../../components/richText/utils';
import { ButtonLink } from '../../design/components/button/Button';
import TwoCtaPlacementContainer from '../../design/containers/placements/TwoCtaPlacementContainer';
import { SideSliceContentContainer } from '../../design/containers/sliceContainer/SideSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing from '../../design/spacing/Spacing';
import Heading2 from '../../design/typography/Heading2';
import { BodyP } from '../../design/typography/Typography';
import weelMaskMobile from '../../images/mask-layer-quote-mb.png';
import weelMask from '../../images/mask-layer-quote.png';
import { WithClassName } from '../../utlityTypes';

const MaskedImageRoot = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 45%;
  max-height: 75%;
  ${breakpointSmall(css`
    top: 0;
    max-height: 100%;
  `)}
`;
const MaskLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${weelMaskMobile});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  ${breakpointSmall(css`
    top: 0;
    background-image: url(${weelMask});
  `)}
`;

const MaskedImageElement = styled(PrismicImage)`
  width: 230%;
  ${breakpointSmall(css`
    height: 100%;
    width: 100%;
  `)}
`;

type MaskedImageProps = { image: ComponentProps<typeof PrismicImage>['image'] };
const MaskedImage: FC<MaskedImageProps> = ({ image }) => (
  <MaskedImageRoot>
    <MaskedImageElement image={image} />
    <MaskLayer />
  </MaskedImageRoot>
);

type QuoteTextProps = { quoteText: PrismicRichTextType };
const QuoteText: FC<QuoteTextProps> = ({ quoteText }) => {
  const quoteTextWithQuotesAdded = {
    richText: [{ ...quoteText?.richText[0], text: `“${quoteText?.richText[0].text}”` }],
  };

  return getRichTextFieldText(quoteText).length <= 100 ? (
    <Heading2 textAlign="left">{quoteTextWithQuotesAdded}</Heading2>
  ) : (
    <RichText textType="quotes" field={quoteTextWithQuotesAdded} />
  );
};

type QuoteProps = {
  quoteText: PrismicRichTextType;
  quoteAuthor?: string;
  primaryCta: LinkType;
  secondaryCta: LinkType;
  image: ComponentProps<typeof PrismicImage>['image'] | null;
} & WithClassName;
/**
 * Renders Quote slice.
 */
const Quote: FC<QuoteProps> = ({ className, quoteAuthor, quoteText, primaryCta, secondaryCta, image }) => {
  return (
    <SliceContainer className={className} background="brand5Solid">
      <SideSliceContentContainer side="left">
        {quoteText && <QuoteText quoteText={quoteText} />}
        <Spacing top="24px">
          <BodyP type="bodySBold" textAlign="left">
            {quoteAuthor}
          </BodyP>
        </Spacing>
        <Spacing top="24px" bottom={{ base: '100px', breakpointSmall: 'none' }}>
          <TwoCtaPlacementContainer
            cta1Placement={
              <ButtonLink styleType="primary" link={primaryCta.link}>
                {primaryCta.text}
              </ButtonLink>
            }
            cta2Placement={
              <ButtonLink styleType="secondary" link={secondaryCta.link}>
                {secondaryCta.text}
              </ButtonLink>
            }
          />
        </Spacing>
      </SideSliceContentContainer>
      <MaskedImage image={image} />
    </SliceContainer>
  );
};
export default Quote;
