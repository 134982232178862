import React, { useEffect, useCallback, FC } from 'react';
import ReactHubspotForm from 'react-hubspot-form';
import styled from 'styled-components';
import { LinkType } from '../../../components/link/types';
import { brand1, brand1Dark, brand4 } from '../../../design/colors/brand';
import { grey500, white } from '../../../design/colors/greyscale';
import { error, successDark } from '../../../design/colors/system';
import { primaryTypeStyle } from '../../../design/components/button/styles/buttonType/brand/primary/primary';
import { largeStyles } from '../../../design/components/button/styles/size/large/large';
import { spacings } from '../../../design/spacing/Spacing';
import { getTypographyStyles } from '../../../design/typography/Typography';
import { fontFamilyStyles } from '../../../design/typography/base/Text';

const Container = styled.div`
  background: ${white};
  padding: ${spacings['48px']};
  border-radius: ${spacings['24px']};
`;

const FormStyle = styled.div`
  ${fontFamilyStyles}
  ${getTypographyStyles('bodyMRegular')}
  line-height: 100%;
  color: ${brand4};

  label {
    display: block;
    margin-bottom: ${spacings['8px']};
    display: flex;
  }

  .hs_company {
    margin-top: ${spacings['8px']};
  }

  .form-columns-1.form-columns-1.form-columns-1 .input {
    margin: 0;
  }

  .form-columns-2.form-columns-2.form-columns-2 .input {
    margin: 0 ${spacings['8px']} 0 0;
  }

  .form-columns-2.form-columns-2.form-columns-2 .hs-form-field:last-child .input {
    margin: 0;
  }

  .hs-button.hs-button.hs-button {
    ${primaryTypeStyle()}
    ${largeStyles()}
    line-height: 100%;
    margin-top: ${spacings['32px']};
  }

  .hs-input.hs-input.hs-input {
    height: 42px;
    width: 100%;
    margin-bottom: ${spacings['32px']};
    border: 1px solid ${grey500};
    border-radius: ${spacings['4px']};
    padding: ${spacings['12px']};
    background-color: inherit;

    &:focus {
      border: 1px solid ${brand1Dark};
    }

    &[type='checkbox'] {
      width: ${spacings['24px']};
      height: ${spacings['24px']};
      margin-bottom: 0;
      appearance: auto;
    }

    &[type='checkbox' i]:checked {
      width: ${spacings['24px']};
      height: ${spacings['24px']};
      accent-color: ${brand1};
      border: 1px solid ${brand1Dark};
      appearance: auto;
      margin: 0;
    }
  }

  .hs-form-booleancheckbox-display.hs-form-booleancheckbox-display {
    display: grid;
    grid-template-columns: 35px 1fr;
    height: 30px;
  }

  .error.error.error {
    border: 1px solid ${error};
    margin-bottom: ${spacings['12px']};
  }

  .hs-main-font-element {
    color: ${error};
  }

  .hs-error-msg.hs-error-msg.hs-error-msg {
    color: ${error};
    margin-bottom: ${spacings['12px']};
  }

  li {
    margin-bottom: 0;
  }

  .hs-form-booleancheckbox-display {
    display: flex;
    align-items: center;
  }

  form.fieldset {
    width: 100%;
  }

  .submitted-message {
    color: ${successDark};
  }
`;

const trackingReducer = (arr: any[]) => {
  if (!arr) return {};
  return arr.reduce((accumulator, current) => ({ ...accumulator, [current.name]: current.value }), {});
};

export type HubspotFormData = { name: string; value: string; valid: boolean }[];
const formIsInvalid = (formData: HubspotFormData) => formData.some((data) => data.valid === false);

const hubspotProductionPortalId = '4814723';

export type HubspotProps = {
  formType?: string;
  id: string;
  link: LinkType['link'];
  trackingEvent: string;
};
type Props = { onSubmitCompleted?: (formData: HubspotFormData) => void } & HubspotProps;
/**
 * Renders hubspot form.
 */
const HubspotForm: FC<Props> = ({ id, link, trackingEvent, onSubmitCompleted }) => {
  const handler = useCallback(
    (event: any) => {
      const analytics = (window as any).analytics as any;
      if (!event.data || !event.data.data || !event.data.data.length) return;
      const formData = event.data.data as HubspotFormData;
      if (formIsInvalid(formData)) return;

      const emailData = formData.find(({ name }) => name === 'email');
      const email = emailData?.value;
      const trackingData = trackingReducer(formData);
      if (analytics) {
        analytics.track(`${trackingEvent}`, {
          ...trackingData,
        });
      }
      if (email && !email.includes('@gmail.com') && link && link) {
        window.open(link.url || undefined, '_blank');
      }
      onSubmitCompleted && onSubmitCompleted(formData);
    },
    [link, onSubmitCompleted, trackingEvent]
  );

  useEffect(() => {
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, [handler]);

  const hsPortalId = hubspotProductionPortalId;
  const hsFormId = id;

  return (
    <Container>
      <FormStyle>
        <ReactHubspotForm portalId={hsPortalId} formId={hsFormId} loading={<div>Loading...</div>} />
      </FormStyle>
    </Container>
  );
};

export default HubspotForm;
