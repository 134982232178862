import { PrismicRichText } from '@prismicio/react';
import React, { FC } from 'react';
import { Heading1, Heading2, Heading3, Heading4 } from '../../design/typography/Typography';
import { TextProps } from '../../design/typography/base/Text';
import { stripInvalidCharacters } from '../../design/typography/base/utils';
import { WithClassName } from '../../utlityTypes';
import {
  Bold,
  ConditionalStyleProvider,
  Italic,
  OrderedListItem,
  Paragraph,
  RichTextLink,
  UnorderedListItem,
} from './components';
import { ConfigurableTextType, getConcreteTextType } from './utils';

export type PrismicRichTextType = Queries.RichTextFragment | null;
export type RichTextProps = {
  /**
   * The type of text style to apply. Can be configured in two ways.
   *
   * If `typeText` is omitted or part of the per tag config is omitted, it will use the default for the tag.
   * The defaults are `heading1`-`heading4` for the headings and `bodyMRegular` for all other tags.
   *
   * @example
   * // 1. Standard - This just sets the text style to all tags that are present in the rich text field.
   * <RichText textType="heading1" field={field} />
   *
   * // 2. Per tag - This allows setting different text styles for different tags.
   * <RichText textType={{ heading1: 'heading2', heading2: 'heading3' }} field={field} />
   *
   * // Both of these ways of configuring can be responseive.
   * <RichText textType={{ base: 'heading2', breakpointSmall: 'heading1' }} field={field} />
   * <RichText
   *   textType={{
   *     heading1: { base: 'heading2', breakpointSmall: 'heading1' },
   *     heading2: { base: 'heading3', breakpointSmall: 'heading2' },
   *   }}
   *   field={field}
   * />
   */
  textType?: ConfigurableTextType;
  field: PrismicRichTextType;
} & TextProps &
  WithClassName;

/**
 * Renders rich text fields.
 */
const RichText: FC<RichTextProps> = ({ className, textType, field, ...props }) => {
  const richText = field?.richText.map((node: any) =>
    node.text && !node.spans?.length ? { ...node, text: stripInvalidCharacters(node.text) } : node
  );

  return (
    <ConditionalStyleProvider className={className}>
      <PrismicRichText
        field={richText}
        components={{
          heading1: ({ children }) => (
            <Heading1 type={getConcreteTextType('heading1', textType)} {...props}>
              {children}
            </Heading1>
          ),
          heading2: ({ children }) => (
            <Heading2 type={getConcreteTextType('heading2', textType)} {...props}>
              {children}
            </Heading2>
          ),
          heading3: ({ children }) => (
            <Heading3 type={getConcreteTextType('heading3', textType)} {...props}>
              {children}
            </Heading3>
          ),
          heading4: ({ children }) => (
            <Heading4 type={getConcreteTextType('heading4', textType)} {...props}>
              {children}
            </Heading4>
          ),
          paragraph: ({ children }) => (
            <Paragraph type={getConcreteTextType('paragraph', textType)}>{children}</Paragraph>
          ),
          strong: ({ children }) => <Bold type={getConcreteTextType('strong', textType)}>{children}</Bold>,
          em: ({ children }) => <Italic type={getConcreteTextType('em', textType)}>{children}</Italic>,
          hyperlink: ({ children, node }) => (
            <RichTextLink type={getConcreteTextType('em', textType)} node={node}>
              {children}
            </RichTextLink>
          ),
          listItem: ({ children }) => <UnorderedListItem>{children}</UnorderedListItem>,
          oListItem: ({ children }) => <OrderedListItem>{children}</OrderedListItem>,
        }}
      />
    </ConditionalStyleProvider>
  );
};

export default RichText;
