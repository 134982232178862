export const grey900 = '#262626';
export const grey800 = '#37474F';
export const grey700 = '#546E7A';
export const grey600 = '#78909C';
export const grey500 = '#B0BEC5';
export const grey400 = '#CFD8DC';
export const grey300 = '#ECEFF1';
export const grey200 = '#F5F5F5';
export const grey100 = '#FAFAFA';
export const white = '#FFFFFF';
