import { css } from 'styled-components';

/**
 * Generates styles to hide or show element (display: none prevents ability to animate/transition).
 */
export const toggleShow = (showElement?: boolean) =>
  showElement
    ? css`
        opacity: 1;
        height: auto;
        width: auto;
        overflow: visible;
        visibility: visible;
      `
    : css`
        opacity: 0;
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;
      `;

/**
 * Truncates paragraphs by x amount of lines and append ellipses.
 */
export const multiLineEllipsis = (clamp: number) =>
  css`
    display: -webkit-box;
    -webkit-line-clamp: ${clamp};
    overflow: hidden;
    -webkit-box-orient: vertical;
  `;
