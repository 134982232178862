import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Faqs from '../Faqs';

type FaqsDataProps = SliceProps<
  'faqs',
  SliceVariation<'default', Queries.PrismicFaqsDefaultPrimary, Queries.PrismicFaqsDefaultItem>
> &
  WithClassName;
/**
 * Faqs data component: specifies the fragment and passes the appropriate data to the Faqs component.
 */
const FaqsData: FC<FaqsDataProps> = ({ className, slice }) => (
  <Faqs className={className} heading={slice.primary.heading} items={slice.items || []} />
);

export default FaqsData;

export const query = graphql`
  fragment Faqs on PrismicFaqsDefault {
    primary {
      heading {
        ...RichText
      }
    }
    items {
      heading
      text
    }
  }
`;
