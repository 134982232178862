import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled, { css } from 'styled-components';
import opaqueCards from '../../../images/backgrounds/opaque-white-cards.png';
import brandmarkSlowSpinAzul from '../../../images/brandmark-slow-spin-azul.gif';
import expressiveFlatAzulMotion from '../../../images/expressive-flat-azul-motion.gif';
import expressiveGlassTeal from '../../../images/expressive-glass-teal.gif';
import glassSpinningWeelImage from '../../../images/glass-spinning-weel-optimised.gif';
import { textSelect } from '../../animations/micro-interactions';
import {
  brand1,
  brand2,
  brand2Mid2,
  brand3,
  brand3Dark,
  brand3Light,
  brand4,
  brand4Light,
  brand5,
} from '../../colors/brand';
import {
  brand2Gradient as brand2GradientColor,
  brand3Gradient as brand3GradientColor,
  brand4Gradient as brand4GradientColor,
} from '../../colors/gradients';
import { white, grey200 } from '../../colors/greyscale';
import { breakpointSmall, breakpointMedium, breakpointLarge, breakpointXSmall } from '../../responsive/breakpoints';
import CloneChild from '../../utils/CloneChild';
import {
  BelowBreakpointSmallStylesProvider,
  BreakpointSmallStylesProvider,
} from '../../utils/responsiveStyleProviders';

type StaticBackgroundImageStyleProviderProps = {
  horizontalPosition?: 'left' | 'right';
  verticalPosition?: 'top' | 'bottom';
  fullHeight?: boolean;
  fullWidth?: boolean;
};
const StaticBackgroundImageStyleProvider = styled(CloneChild)<StaticBackgroundImageStyleProviderProps>`
  && {
    position: absolute;
    ${({ horizontalPosition = 'left', verticalPosition = 'bottom' }) => css`
      ${horizontalPosition === 'left' ? 'left: 0' : 'right: 0'};
      ${verticalPosition === 'bottom' ? 'bottom: 0' : 'top: 0'};
    `}
    ${(props) => props.fullHeight && 'height: 100%;'}
    ${(props) => props.fullWidth && 'width: 100%;'}
  }
`;

const backgroundImageStyles = css`
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateZ(0);
  isolation: isolate;
`;

const LimeSnakeImageComponent = () => (
  <>
    <BelowBreakpointSmallStylesProvider>
      <StaticBackgroundImageStyleProvider horizontalPosition="right">
        <StaticImage src="../../../images/backgrounds/lime-snake-mobile.png" alt="striped curve feature mobile" />
      </StaticBackgroundImageStyleProvider>
    </BelowBreakpointSmallStylesProvider>

    <BreakpointSmallStylesProvider>
      <StaticBackgroundImageStyleProvider horizontalPosition="right">
        <StaticImage src="../../../images/backgrounds/lime-snake-desktop.png" alt="striped curve feature desktop" />
      </StaticBackgroundImageStyleProvider>
    </BreakpointSmallStylesProvider>
  </>
);

export const whiteSolid = {
  isDark: false,
  styles: css`
    ${textSelect({ color: brand4, background: brand1 })}
    background: ${white};
    color: ${brand4};
  `,
};

export const grey200Solid = {
  isDark: false,
  styles: css`
    ${textSelect({ color: brand4, background: brand1 })}
    background: ${grey200};
    color: ${brand4};
  `,
};

export const brand5GlassSpinnerLime = {
  isDark: false,
  styles: css`
    ${textSelect({ color: brand4, background: brand1 })}
    background: url(${glassSpinningWeelImage}), ${brand5};
    ${backgroundImageStyles}
    background-position: left bottom;
    color: ${brand4};
  `,
};

export const lightOpaqueCards = {
  isDark: true,
  styles: css`
    background: url(${opaqueCards}), ${brand2};
    ${backgroundImageStyles}
    background-position: right bottom;
    color: ${white};
    background-size: 40%;
    ${breakpointXSmall(css`
      background-size: 30%;
    `)}
    ${breakpointSmall(css`
      background-size: 50%;
    `)}
    ${breakpointMedium(css`
      background-size: 45%;
    `)}
    ${breakpointLarge(css`
      background-size: 35%;
    `)}
  `,
};

export const transparent = {
  isDark: false,
  styles: css`
    ${textSelect({ color: brand4, background: brand1 })}
    background: transparent;
  `,
};

export const darkOpaqueCards = {
  isDark: true,
  styles: css`
    background: url(${opaqueCards}), ${brand3};
    ${backgroundImageStyles}
    background-position: right bottom;
    color: ${white};
    background-size: 40%;
    ${breakpointXSmall(css`
      background-size: 30%;
    `)}
    ${breakpointSmall(css`
      background-size: 50%;
    `)}
    ${breakpointMedium(css`
      background-size: 45%;
    `)}
    ${breakpointLarge(css`
      background-size: 35%;
    `)}
  `,
};

export const brand2GlassSpinnerTeal = {
  isDark: true,
  styles: css`
    ${textSelect({ color: white, background: brand3Dark })}

    background: ${brand2};
    color: ${white};
  `,
  ImageComponent: () => (
    <StaticBackgroundImageStyleProvider>
      <StaticImage src="../../../images/glass-spinner-teal.svg" alt="glass spinner teal" />
    </StaticBackgroundImageStyleProvider>
  ),
};

export const brand2Solid = {
  isDark: true,
  styles: css`
    ${textSelect({ color: white, background: brand3Dark })}
    background: ${brand2};
    color: ${white};
  `,
};

export const brand2SolidStripedCurveLeft = {
  ...brand2Solid,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/lime-snake-left-mobile.png"
            alt="striped curve left feature mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/lime-snake-left-desktop.png"
            alt="striped curve left feature desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand2WaveTeal = {
  ...brand2Solid,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right">
          <StaticImage
            src="../../../images/backgrounds/brand2-expressive-wave-mobile.png"
            alt="brand 2 expressive wave mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right">
          <StaticImage
            src="../../../images/backgrounds/brand2-expressive-wave-right-desktop.png"
            alt="brand 2 expressive wave right desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand2LogoRight = {
  ...brand2Solid,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/brand2-weel-logo-right-desktop.png"
            alt="brand 2 weel logo right mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right">
          <StaticImage
            src="../../../images/backgrounds/brand2-weel-logo-right-desktop.png"
            alt="brand 2 weel logo right desktop"
            style={{ scale: '50%', bottom: '-126px', right: '-210px' }}
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand2Gradient = {
  isDark: true,
  styles: css`
    ${textSelect({ color: white, background: brand3Dark })}
    background: ${brand2GradientColor};
    color: ${white};
  `,
};

export const brand2GradientStripedCurve = {
  ...brand2Gradient,
  ImageComponent: LimeSnakeImageComponent,
};

export const brand3Solid = {
  isDark: true,
  styles: css`
    ${textSelect({ color: brand3, background: brand2Mid2 })}
    background: ${brand3};
    color: ${white};
  `,
};

const Brand3SolidStripedCurveImageStylesProvider = styled(CloneChild)`
  height: 85%;
`;
export const brand3SolidStripedCurve = {
  ...brand3Solid,
  ImageComponent: () => (
    <BreakpointSmallStylesProvider>
      <Brand3SolidStripedCurveImageStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/azul-expressive-wave-desktop.png"
            alt="azul expressive wave"
            objectPosition="bottom left"
            objectFit="contain"
          />
        </StaticBackgroundImageStyleProvider>
      </Brand3SolidStripedCurveImageStylesProvider>
    </BreakpointSmallStylesProvider>
  ),
};

export const Brand3SolidExpressiveWave = {
  ...brand3Solid,
  ImageComponent: () => (
    <BreakpointSmallStylesProvider>
      <Brand3SolidStripedCurveImageStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/azul-expressive-wave2-desktop.png"
            alt="azul expressive wave"
            objectPosition="bottom left"
            objectFit="contain"
          />
        </StaticBackgroundImageStyleProvider>
      </Brand3SolidStripedCurveImageStylesProvider>
    </BreakpointSmallStylesProvider>
  ),
};

export const brand2CardStackTeal = {
  ...brand2Solid,
  ImageComponent: () => (
    <>
      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/brand2-card-stack-desktop.png"
            alt="brand 4 card stack midnight desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand3Gradient = {
  isDark: true,
  styles: css`
    ${textSelect({ color: brand4, background: brand3Light })}

    background: ${brand3GradientColor};
    color: ${white};
  `,
};

export const brand3GradientStripedCurve = {
  ...brand3Gradient,
  ImageComponent: LimeSnakeImageComponent,
};

export const brand3GradientStripedCurveLeft = {
  ...brand3Gradient,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/lime-snake-left-mobile.png"
            alt="striped curve left feature mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/lime-snake-left-desktop.png"
            alt="striped curve left feature desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand3FlexSpinnerAzul = {
  isDark: true,
  styles: css`
    ${textSelect({ color: brand3, background: brand2Mid2 })}
    background: url(${expressiveFlatAzulMotion}), ${brand3};
    ${backgroundImageStyles}
    background-position: left bottom;
    color: ${brand5};
  `,
};

export const brand3WeelLogoAzul = {
  isDark: true,
  styles: css`
    background: url(${brandmarkSlowSpinAzul}), ${brand3};
    ${backgroundImageStyles}
    background-position: left bottom;
    color: ${brand5};
  `,
};

export const brand3CardStackAzul = {
  ...brand3Gradient,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/brand3-card-stack-azul-mobile.png"
            alt="brand 3 card stack azul mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right">
          <StaticImage
            src="../../../images/backgrounds/brand3-card-stack-azul-desktop.png"
            alt="brand 3 card stack azul desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand3HomepageHero = {
  isDark: true,
  styles: css`
    background: #064e59;
    color: ${white};
  `,
};

export const brand4Gradient = {
  isDark: true,
  styles: css`
    ${textSelect({ color: brand4, background: brand2Mid2 })}
    background: ${brand4GradientColor};
    color: ${white};
  `,
};

export const brand4GradientWeelLogo = {
  ...brand4Gradient,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right">
          <StaticImage
            src="../../../images/backgrounds/brand4-weel-logo-hills-mobile.png"
            alt="weel logo feature mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right">
          <StaticImage
            src="../../../images/backgrounds/brand4-weel-logo-hills-desktop.png"
            alt="weel logo feature desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand4GradientWeelLogoLeft = {
  ...brand4Gradient,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/brand4-weel-logo-hills-left-mobile.png"
            alt="weel logo left feature mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/brand4-weel-logo-hills-left-desktop.png"
            alt="weel logo left feature desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand4WaveMidnight = {
  ...brand4Gradient,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/brand4-expressive-wave-mobile.png"
            alt="brand 4 expressive wave mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/brand4-expressive-wave-left-desktop.png"
            alt="brand 4 expressive wave left desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand4WaveMidnightRight = {
  ...brand4Gradient,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right">
          <StaticImage
            src="../../../images/backgrounds/brand4-expressive-wave-mobile.png"
            alt="brand 4 expressive wave mobile"
            style={{ transform: 'scaleX(-1)' }}
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right">
          <StaticImage
            src="../../../images/backgrounds/brand4-expressive-wave-left-desktop.png"
            alt="brand 4 expressive wave left desktop"
            style={{ transform: 'scaleX(-1)' }}
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand4CardStackMidnight = {
  ...brand4Gradient,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/brand4-card-stack-midnight-mobile.png"
            alt="brand 4 card stack midnight mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/brand4-card-stack-midnight-desktop.png"
            alt="brand 4 card stack midnight desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

const Brand4LightSolidWeelLogoPositionProvider = styled(CloneChild)`
  && {
    position: absolute;
    top: 300px;
    left: -60px;
    width: 700px;
  }
`;
export const brand4LightSolidWeelLogo = {
  isDark: true,
  styles: css`
    ${textSelect({ color: brand4, background: brand1 })}
    background: ${brand4Light};
  `,
  ImageComponent: () => (
    <>
      <Brand4LightSolidWeelLogoPositionProvider>
        <StaticImage src="../../../images/backgrounds/brand-5-weel-logo-full.png" alt="light green weel logo feature" />
      </Brand4LightSolidWeelLogoPositionProvider>
    </>
  ),
};

export const brand4GradientMidnightLogoLeft = {
  ...brand4Gradient,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/brand4-weel-logo-left-desktop.png"
            alt="dark blue weel logo feature"
            style={{ height: '100%', maxWidth: '400px', left: '-35%' }}
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>
      {/* @todo COME BACK TO THIS for mobile */}
      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/brand4-weel-logo-left-desktop.png"
            alt="dark blue weel logo feature"
            style={{ height: '100%', maxWidth: '400px' }}
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand4GradientMidnightCardLeft = {
  ...brand4Gradient,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/brand4-card-cutout-full-mobile.png"
            alt="dark blue weel logo cutout mobile"
            style={{ top: '-141px', width: '100%', height: '100%' }}
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/brand4-card-cutout-full-desktop.png"
            alt="dark blue weel logo cutout desktop"
            style={{ top: '0', maxWidth: '1150px', left: '-373px' }}
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand1SolidWeelLogo = {
  isDark: false,
  styles: css`
    ${textSelect({ color: brand4, background: brand1 })}
    background: ${white};
    color: ${brand4};
  `,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right" verticalPosition="top">
          <StaticImage
            src="../../../images/backgrounds/brand1-weel-logo.png"
            alt="light green weel logo feature"
            objectFit="contain"
            style={{ height: 'auto', width: '139px' }}
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right" verticalPosition="top">
          <StaticImage
            src="../../../images/backgrounds/brand1-weel-logo.png"
            alt="light green weel logo feature"
            objectFit="contain"
            style={{ height: 'auto', width: '139px' }}
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand5Solid = {
  isDark: false,
  styles: css`
    ${textSelect({ color: brand4, background: brand1 })}
    background: ${brand5};
    color: ${brand4};
  `,
};

export const brand5SolidCardsLeft = {
  ...brand5Solid,
  ImageComponent: () => (
    <BreakpointSmallStylesProvider>
      <StaticBackgroundImageStyleProvider horizontalPosition="left" fullHeight>
        <StaticImage
          src="../../../images/backgrounds/light-glass-cards-left.png"
          alt="glass cards feature"
          objectFit="contain"
          objectPosition="left"
        />
      </StaticBackgroundImageStyleProvider>
    </BreakpointSmallStylesProvider>
  ),
};

export const brand5WaveLime = {
  ...brand5Solid,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/lime-expressive-wave-mobile.png"
            alt="lime expressive wave mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right">
          <StaticImage
            src="../../../images/backgrounds/lime-expressive-wave-right-desktop.png"
            alt="lime expressive wave right"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand5ExpressiveWaveLime = {
  ...brand5Solid,
  ImageComponent: () => (
    <>
      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right" fullWidth>
          <StaticImage
            src="../../../images/backgrounds/lime-expressive-wave-still.png"
            alt="lime expressive wave right"
            style={{ bottom: '-90px' }}
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand5WaveLimeRight = {
  ...brand5Solid,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right">
          <StaticImage
            src="../../../images/backgrounds/brand5-expressive-wave-left-mobile.png"
            alt="brand 5 expressive wave mobile"
            style={{ transform: 'scaleX(-1)' }}
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="right">
          <StaticImage
            src="../../../images/backgrounds/brand5-expressive-wave-right-desktop.png"
            alt="brand 5 expressive wave left desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand5CardTwistTeal = {
  ...brand5Solid,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/brand5-weel-logo-hills-mobile.png"
            alt="brand 5 weel logo hills mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/brand5-weel-logo-hills-left-desktop.png"
            alt="brand 5 weel logo hills left desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand5FlexSpinnerBase = {
  ...brand5Solid,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/brand5-flex-spinner-base-mobile.png"
            alt="brand 5 flex spinner base mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/brand5-flex-spinner-base-desktop.png"
            alt="brand 5 flex spinner base desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand5WeelLogoBase = {
  ...brand5Solid,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/brand5-weel-logo-base-mobile.png"
            alt="brand 5 weel logo base mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/brand5-weel-logo-base-desktop.png"
            alt="brand 5 weel logo base desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand5FlexSpinnerLime = {
  ...brand5Solid,
  ImageComponent: () => (
    <>
      <BelowBreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider>
          <StaticImage
            src="../../../images/backgrounds/brand5-flex-spinner-lime-mobile.png"
            alt="brand 5 flex spinner lime mobile"
          />
        </StaticBackgroundImageStyleProvider>
      </BelowBreakpointSmallStylesProvider>

      <BreakpointSmallStylesProvider>
        <StaticBackgroundImageStyleProvider horizontalPosition="left">
          <StaticImage
            src="../../../images/backgrounds/brand5-flex-spinner-lime-desktop.png"
            alt="brand 5 flex spinner lime desktop"
          />
        </StaticBackgroundImageStyleProvider>
      </BreakpointSmallStylesProvider>
    </>
  ),
};

export const brand5GlassSpinnerTeal = {
  isDark: false,
  styles: css`
    ${textSelect({ color: brand4, background: brand1 })}
    background: url(${expressiveGlassTeal}), ${brand5};
    ${backgroundImageStyles}
    background-position: left bottom;
    color: ${brand4};
  `,
};
