import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '../../../design/icons/Icon';
import { spacings } from '../../../design/spacing/Spacing';
import { BodySpan } from '../../../design/typography/Typography';
import { WithClassName } from '../../../utlityTypes';
import { ItemProps } from '../columns/PricingColumns';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings['16px']};
`;

const ListItem = styled.div`
  display: flex;
  gap: ${spacings['12px']};
`;

type ListProps = { heading?: ItemProps['itemHeading']; items: any[]; reverse?: boolean } & WithClassName;
/**
 * Renders check list items for Price slice.
 */
const List: FC<ListProps> = ({ heading, reverse, items, className }) => {
  return (
    <ListContainer className={className}>
      {heading && (
        <BodySpan type="bodyLMedium" textAlign="left">
          {heading}
        </BodySpan>
      )}
      {items.map((item) => (
        <ListItem key={item}>
          {item && <Icon type="Check" reverse={reverse} />}
          <BodySpan type="bodyLRegular" textAlign="left">
            {item}
          </BodySpan>
        </ListItem>
      ))}
    </ListContainer>
  );
};

export default List;
