import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { multiLineEllipsis } from '../../../design/animations/display';
import { jump } from '../../../design/animations/micro-interactions';
import { solidBackgrounds } from '../../../design/colors/background';
import { brand2, brand2Mid } from '../../../design/colors/brand';
import { white } from '../../../design/colors/greyscale';
import { blogWysiwygShadow } from '../../../design/colors/shadows';
import { ButtonLink } from '../../../design/components/button/Button';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointXSmall } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP, headingStyles } from '../../../design/typography/Typography';
import Text from '../../../design/typography/base/Text';

const CardSmallText = styled(BodyP)``;
CardSmallText.defaultProps = { type: 'bodyMBold' };

const Title = styled(Text)`
  ${headingStyles['heading3']};
`;
Title.defaultProps = { textAlign: 'left' };

const Excerpt = styled(BodyP)`
  margin: ${spacings['12px']} 0;
  ${multiLineEllipsis(4)};
`;

const Cta = styled(ButtonLink)`
  max-width: fit-content;
`;

const Card = styled(Panel)`
  ${jump}
  ${blogWysiwygShadow}
  :nth-child(5n),
  :nth-child(4n + 1),
  :nth-child(4n) {
    background: ${white};
    ${CardSmallText} {
      color: ${brand2};
    }
  }
  :nth-child(4n - 1) {
    background: ${solidBackgrounds.brand2};
    ${CardSmallText} {
      color: ${brand2Mid};
    }
    ${Title}, ${Excerpt} {
      color: ${white};
    }
  }
  :nth-child(4n - 2) {
    background: ${solidBackgrounds.brand3};
    ${CardSmallText} {
      color: ${brand2Mid};
    }
    ${Title}, ${Excerpt} {
      color: ${white};
    }
  }
  padding: ${spacings['24px']};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacings['12px']};
  ${breakpointXSmall(
    css`
      padding: ${spacings['48px']};
    `
  )};
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings['12px']};
`;
// @todo refactoring nth child logic into ts to handle like other backgrounds
type CardProps = {
  date: string;
  title: string;
  author: string;
  excerpt: string;
  url: string;
};
/**
 * Blog Card component: sits inside PostsGrid component on Blog Lannding page.
 */
const BlogCard: FC<CardProps> = ({ date, title, author, excerpt, url }) => {
  const link = {
    link_type: 'Document',
    url,
  } as const;

  return (
    <Card>
      <TextContainer>
        {date && <CardSmallText>{date}</CardSmallText>}
        <Title>{title}</Title>
        {author && <CardSmallText>{author}</CardSmallText>}
        {excerpt && <Excerpt>{excerpt}</Excerpt>}
      </TextContainer>
      <Cta styleType="primary" size="medium" link={link}>
        Read more
      </Cta>
    </Card>
  );
};

export default BlogCard;
