import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicRichTextType } from '../../../components/richText/RichText';
import { brand2Mid2 } from '../../../design/colors/brand';
import { blogHeaderShadow } from '../../../design/colors/shadows';
import { Panel } from '../../../design/containers/panel/Panel';
import { CenteredSliceContentContainer } from '../../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../../design/containers/sliceContainer/SliceContainer';
import { breakpointLarge } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import Heading1 from '../../../design/typography/Heading1';
import { BodyP } from '../../../design/typography/Typography';
import { topOffsetAmount } from '../ContentWrapper/ContentWrapper';

const blogShadowPaddingAllowance = 'padding-top: 12px;';

const BlogHeaderContainer = styled(SliceContainer)`
  ${blogShadowPaddingAllowance}
  padding-left: 0;
  padding-right: 0;
  margin-bottom: ${spacings['24px']};
`;

const HeaderContainer = styled(Panel)`
  ${blogHeaderShadow}
  width: 100%;
  min-height: 350px;
  z-index: 2;
  position: relative;
  margin: ${spacings['24px']} auto 0;
  ${breakpointLarge(
    css`
      min-height: 430px;
    `
  )};
`;
HeaderContainer.defaultProps = { background: 'brand3WeelLogoAzul', borderRadius: `${spacings['32px']}` };

const CenteredContainer = styled(CenteredSliceContentContainer)`
  width: 100%;
  margin: 0 auto;
  align-items: baseline;
  padding: ${spacings['100px']} ${spacings['24px']} ${topOffsetAmount}px ${spacings['24px']};
`;

const AuthorContainer = styled.div`
  display: flex;
  gap: ${spacings['24px']};
  padding-top: ${spacings['24px']};
`;

const PostDetail = styled(BodyP)`
  color: ${brand2Mid2};
`;
PostDetail.defaultProps = { type: 'bodyMRegular' };

type BlogHeaderProps = {
  title?: PrismicRichTextType | '';
  date?: string | '';
  author?: string | '';
};
/**
 * Renders Blog Post Header.
 */
const BlogHeader: FC<BlogHeaderProps> = ({ title, date, author }) => {
  return (
    <BlogHeaderContainer>
      <HeaderContainer>
        <CenteredContainer verticalPadding="none">
          {title && <Heading1 textAlign="left">{title}</Heading1>}
          <AuthorContainer data-testid="author-container">
            {date && <PostDetail>{date}</PostDetail>}
            {author && <PostDetail>By {author}</PostDetail>}
          </AuthorContainer>
        </CenteredContainer>
      </HeaderContainer>
    </BlogHeaderContainer>
  );
};

export default BlogHeader;
