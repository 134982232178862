import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { IconType } from '../../../design/icons/Icon';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import TwoColumnThreeCards from '../TwoColumnThreeCards';

type TwoColumnThreeCardsDataProps = SliceProps<
  'threeColumnCards',
  SliceVariation<'default', Queries.PrismicTwoColumnThreeCardsDefaultPrimary>
> &
  WithClassName;
/**
 * TwoColumnThreeCards data component: specifies the fragment and passes the appropriate data to the TwoColumnThreeCards component.
 */
const TwoColumnThreeCardsData: FC<TwoColumnThreeCardsDataProps> = ({ className, slice }) => {
  return (
    <TwoColumnThreeCards
      className={className}
      heading={slice.primary.heading}
      text={slice.primary.text || ''}
      leftCard={{
        heading: slice.primary.leftCardHeading || '',
        text: slice.primary.leftCardText || '',
        icon: slice.primary.leftCardIcon as IconType,
        bronze: {
          heading: slice.primary.leftCardBronzeHeading || '',
          text: slice.primary.leftCardBronzeText || '',
          featureText: slice.primary.leftCardBronzeFeatureText || '',
          subText: slice.primary.leftCardBronzeSubText || '',
        },
        silver: {
          heading: slice.primary.leftCardSilverHeading || '',
          text: slice.primary.leftCardSilverText || '',
          featureText: slice.primary.leftCardSilverFeatureText || '',
          subText: slice.primary.leftCardSilverSubText || '',
        },
        gold: {
          heading: slice.primary.leftCardGoldHeading || '',
          text: slice.primary.leftCardGoldText || '',
          featureText: slice.primary.leftCardGoldFeatureText || '',
          subText: slice.primary.leftCardGoldSubText || '',
        },
      }}
      topRightCard={{
        heading: slice.primary.topRightCardHeading || '',
        text: slice.primary.topRightCardText || '',
        icon: slice.primary.topRightCardIcon as IconType,
      }}
      bottomRightCard={{
        heading: slice.primary.bottomRightCardHeading || '',
        text: slice.primary.bottomRightCardText || '',
        icon: slice.primary.bottomRightCardIcon as IconType,
      }}
    />
  );
};

export default TwoColumnThreeCardsData;

/**
 * @todo Convert card headings to rich text.
 */
export const query = graphql`
  fragment TwoColumnThreeCards on PrismicTwoColumnThreeCardsDefault {
    primary {
      heading {
        ...RichText
      }
      text
      leftCardIcon
      leftCardHeading
      leftCardText
      leftCardBronzeHeading
      leftCardBronzeText
      leftCardBronzeFeatureText
      leftCardBronzeSubText
      leftCardSilverHeading
      leftCardSilverText
      leftCardSilverFeatureText
      leftCardSilverSubText
      leftCardGoldHeading
      leftCardGoldText
      leftCardGoldFeatureText
      leftCardGoldSubText
      topRightCardIcon
      topRightCardHeading
      topRightCardText
      bottomRightCardIcon
      bottomRightCardHeading
      bottomRightCardText
    }
  }
`;
