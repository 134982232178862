import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Divider } from '../../design/components/divider/Divider';
import { OnVisible } from '../../design/components/onVisible/OnVisible';
import { Panel } from '../../design/containers/panel/Panel';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { BodySpan } from '../../design/typography/Typography';
import minusImg from '../../images/minius-small.svg';
import plusImg from '../../images/plus-small.svg';

const Container = styled(Panel).attrs({ background: 'whiteSolid', borderRadius: '12px' })`
  padding: ${spacings['32px']};
  &:last-child {
    margin-bottom: 0;
  }
`;

const PlusIcon = styled.div`
  min-width: ${spacings['32px']};
  width: ${spacings['32px']};
  height: ${spacings['32px']};
  background: url(${plusImg}) no-repeat center;
`;

const MinusIcon = styled(PlusIcon)`
  background: url(${minusImg}) no-repeat center;
`;

const HeadingContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const Body = styled.div``;

type FaqItemProps = {
  heading?: string | null;
  text?: string | null;
  index: number;
};

/**
 * Renders FaqItem slice.
 */
const FaqItem: FC<FaqItemProps> = ({ heading, text, index }) => {
  const [showText, setShowText] = useState(false);
  return (
    <OnVisible fadeUp style={{ marginBottom: `${spacings['24px']}` }}>
      <Container>
        <HeadingContainer>
          <Spacing right="12px">
            <BodySpan type="heading5Medium" textAlign="left">
              {heading}
            </BodySpan>
          </Spacing>
          {showText ? (
            <MinusIcon data-testid={`faq-${index}-minus-icon`} onClick={() => setShowText(false)} />
          ) : (
            <PlusIcon data-testid={`faq-${index}-plus-icon`} onClick={() => setShowText(true)} />
          )}
        </HeadingContainer>
        <Body data-testid={`faq-${index}-content`} style={{ display: showText ? '' : 'none' }}>
          <Spacing top="24px" bottom="24px">
            <Divider styleType="brand2MidOpacity0point3" />
          </Spacing>
          <BodySpan type="bodyMRegular" textAlign="left">
            {text}
          </BodySpan>
        </Body>
      </Container>
    </OnVisible>
  );
};

export default FaqItem;
