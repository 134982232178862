import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../components/image/prismicImage/PrismicImage';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { white } from '../../design/colors/greyscale';
import BaseColumnSmallRowContainer from '../../design/containers/responsive/BaseColumnSmallRowContainer';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointXSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import Heading2 from '../../design/typography/Heading2';
import { BodySpan } from '../../design/typography/Typography';
import { WithClassName } from '../../utlityTypes';

const LogosContainer = styled(BaseColumnSmallRowContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: normal;
  justify-content: center;
  gap: ${spacings['16px']};
  width: 100%;
  flex-direction: row;
`;

const LogoImage = styled(PrismicImage)`
  max-width: 70px;
`;

const LogoBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${white};
  border-radius: ${spacings['12px']};
  flex: 40%;
  width: 194px;
  max-height: 108px;
  padding: ${spacings['12px']};
  height: 100px;

  ${breakpointXSmall(css`
    flex: 20%;
  `)}
`;

type Logo = {
  logo: ComponentProps<typeof PrismicImage>['image'];
};
type LogosProps = {
  heading: PrismicRichTextType;
  text?: string;
  items?: Array<Queries.PrismicLogosDefaultItem>;
} & WithClassName;

const LogoPanel: FC<Logo> = ({ logo }) => {
  return (
    <LogoBackground>
      <LogoImage image={logo} />
    </LogoBackground>
  );
};

/**
 * Renders Logos slice.
 */
const Logos: FC<LogosProps> = ({ className, heading, text, items }) => {
  return (
    <SliceContainer className={className} background="brand2CardStackTeal">
      <CenteredSliceContentContainer verticalPadding="100px" maxWidth="814px">
        <Heading2 textAlign="center">{heading}</Heading2>
        <Spacing top={{ base: '24px' }}>
          <BodySpan type="bodyLRegular" textAlign="center">
            {text}
          </BodySpan>
        </Spacing>
        <Spacing top={{ base: '24px', breakpointSmall: '48px' }}>
          <LogosContainer data-testid="logo-container">
            {items?.map((item, index) => (
              <LogoPanel key={index} logo={item.logo} />
            ))}
          </LogosContainer>
        </Spacing>
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};

export default Logos;
