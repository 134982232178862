import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Background } from '../../../design/containers/panel/Panel';
import { WithClassName } from '../../../utlityTypes';
import { SliceProps, SliceVariation } from '../../types';
import BottomCta from '../BottomCta';

const getBackground = (backgroundColor: BottomCtaDataProps['slice']['primary']['backgroundColor']) => {
  const backgroundMap: Record<string, Background> = {
    whiteSolid: 'whiteSolid',
    grey200Solid: 'grey200Solid',
    brand2Solid: 'brand2Solid',
    brand3Solid: 'brand3Solid',
    brand5Solid: 'brand5Solid',
  };
  return backgroundColor ? backgroundMap[backgroundColor] : 'whiteSolid';
};

type BottomCtaDataProps = SliceProps<'bottomCta', SliceVariation<'default', Queries.PrismicBottomCtaDefaultPrimary>> &
  WithClassName;
/**
 * BottomCta data component: specifies the fragment and passes the appropriate data to the BottomCta component.
 */
const BottomCtaData: FC<BottomCtaDataProps> = ({ className, slice }) => (
  <BottomCta
    className={className}
    background={getBackground(slice.primary.backgroundColor)}
    heading={slice.primary.heading}
    text={slice.primary.text || ''}
    subtext={slice.primary.subtext || ''}
    primaryCta={{ text: slice.primary.primaryCtaButtonText || '', link: slice.primary.primaryCtaLink }}
    secondaryCta={{ text: slice.primary.secondaryCtaButtonText || '', link: slice.primary.secondaryCtaLink }}
  />
);

export default BottomCtaData;

export const query = graphql`
  fragment BottomCta on PrismicBottomCtaDefault {
    primary {
      backgroundColor
      heading {
        ...RichText
      }
      text
      subtext
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }
`;
